// check users and replace with items
// confirm status column value in the list to display
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";

import {
  getFaq,
  searchFaq,
  deleteFaqDetail,
} from "../../Redux/Actions/faqsAction";
// import "./faq-list.scss";

class FaqList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      deleteUser: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchFaq(
            this.state.offset,
            this.state.searchKeyword.trim()
          );
        } else {
          result = await this.props.getFaq(this.state.offset);
        }
        console.log(result);

        // let result = await this.props.getUsersList(this.state.offset);
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    // console.log("event value is", +event.target.value);

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
      });

      this.debounceSearchCall();
    }
  };

  deleteUser = async () => {
    this.setState({
      showLoader: true,
    });
    await this.props.deleteFaqDetail(this.state.deleteUser[0]);

    window.location.reload();
    // this.fetchMoreData(false);
    this.setState({
      showLoader: false,
      deleteUser: [],
    });
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  render() {
    const itemsList = this.state.items.map((i) => {
      const date = new Date(i.created_ts)
      return (
        <tr key={i.id}>
          <td align="center">{i.id ? i.id : "-"}</td>
          <td>{i.name ? i.name : "-"}</td>
          {/* <td>{i.parentId ? i.parentId : "-"}</td> */}
          <td>{i.created_ts ? (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear()) : null}</td>
         

          <td align="center">
            <div class="vendor-table-btn">
              <Link className="orange-outline-btn mr-2" to={`edit-faq/${i.id}`}>
                Edit
              </Link>
              <a
                className="orange-outline-btn"
                data-toggle="modal"
                data-target="#deleteUser"
                onClick={() => this.setState({deleteUser: [i.id] })}
              >
                Delete
              </a>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div class="admin-card faq-list-page">
        <div class="admin-card-head">
          <h2>FAQ list</h2>
          <Link className="mb-2" to={"/create-faq"}>
            <button type="text" class="orange-outline-btn">
              <i class="fas fa-plus"></i> Add
            </button>
          </Link>
        </div>

        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">ID</td>
                        <td>Name</td>
                        <td>Created</td>
                        <td align="center">Edit</td>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsList}
                    </tbody>
                  </table>
                </InfiniteScroll>
                {this.state.showLoader ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* deleteUser-modal */}
        {this.state.deleteUser.length !== 0 && (
          <div class="theme-modal modal deleteUser-modal" id="deleteUser">
            <div class="modal-dialog modal-dialog-centered modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <div className="modal-title">
                    <h4>Delete Confirmation</h4>
                  </div>
                </div>

                <div class="modal-body">
                  <p>{`Are you sure to delete?`}</p>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="orange-btn"
                    data-dismiss="modal"
                    onClick={() => this.deleteUser()}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="orange-outline-btn"
                    data-dismiss="modal"
                    onClick={() =>
                      this.setState({ deleteUser: [], actions: "" })
                    }
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* deleteUser-modal */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getFaq, searchFaq, deleteFaqDetail }, dispatch);
};

export default connect(null, mapDispatchToProps)(FaqList);
