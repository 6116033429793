import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";

import {
    getProductList,
    searchProducts,
} from "../../Redux/Actions/productAction";

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    sellersId:"",
    maxLimit: 10,
    offset: 0,
    prevOffset: 0,
    items: [],
    searchKeyword: "",
    hasMore: true,
    showLoader: false,
    product_List:"",
    };

    if (this.props.match.path === "/productList/:sellersId") {
        console.log("editcheck......");
        // this.props.match.params.couponId;
        this.fetchproductList(this.props.match.params.sellersId);
      }
  }
  fetchproductList = async (sellersId) => { 
    console.log(this.state.searchKeyword) 
    let productList

    if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
      productList = await this.props.searchProducts(
        this.state.searchKeyword
      );
  
    if (productList?.data?.data.length > 0) {
      productList = productList?.data?.data;
      this.setState({
        items : productList,
      })
    }
  }
else {
      productList = await this.props.getProductList(sellersId);
      if (productList?.data?.data[0].length > 0) {
        productList = productList?.data?.data[0];
        this.setState({
          items : productList,
        })
      }
    }

this.setState({
  product_List:this.state.items,
})

}

handleChange = async (event, field) => {
  event.preventDefault();

  if (field === "maxLimit") {
    await this.setState({
      [field]:
        event.target.value === "all"
          ? event.target.value
          : +event.target.value,
    });
    this.fetchproductList(this.state.sellersId);
  } else {
    await this.setState({
      [field]: event.target.value,
      offset: 0,
      prevOffset: 0,
      hasMore: true,
    });

    this.debounceSearchCall();
  }
};  
debounceSearchCall = debounce(() => {
  this.setState({
    items: [],
  });
  this.fetchproductList(this.state.sellersId);
}, 1000);

  
  render() {

 
    const itemsList = this.state.items.map((i) => {
   
        return (
          <tr key={i.id}>
            <td align="center">{i.id ? i.id : "-"}</td>
            <td>{i.name ? i.name : "-"}</td>

            <td>{i.productCategoryName ? i.productCategoryName : "-"}</td>
            <td>{i.price ? i.price: "-"}</td>
     
      
            <td>{i.is_active ? "Yes" : "No"}</td>
  
            <td align="center">
          <div class="vendor-table-btn">
          <Link className="orange-outline-btn mr-2" to={`/productDetails/${i.id}`}>
                  View
                </Link>
              
              </div>
            </td>
          </tr>
        );
    });

    return (
   
      <div class="admin-card faq-list-page">
        <div class="admin-card-head">
          <h2>Product list</h2>
        </div>

        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchproductList()}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">ID</td>
                        <td>Name</td>
                        <td>Category</td>
                        <td>Price</td>
                        <td>is Active</td>
                        <td align="center">View Detail</td>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.product_List?.length > 0 ? itemsList : "No Product Found" }
                    </tbody>
                  </table>
                </InfiniteScroll>
                {this.state.showLoader ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getProductList,searchProducts }, dispatch);
  };
  
  export default connect(null, mapDispatchToProps)(ProductsList);
