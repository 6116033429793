import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getReportPostList = (offset, limit = 10) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/report/list?offset=${offset}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      console.log("orders list response from backend", res);
      return res;
    }
    catch (error) {
      console.log('wrong', error);
      dispatch(handleError(error));
    }
  }
 


  export const toInactivePost = (id) => async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/admin/report/${id}`,
       
      });
      console.log("orders list response from backend", res);
      return res;
    }
    catch (error) {
      console.log('wrong', error);
      dispatch(handleError(error));
    }
  }

  export const searchReportedPost = (offset, searchKeyword) => async (dispatch) => {
    
  
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/sellers/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getReportUserList = (offset, limit = 10) => async (dispatch) => {
  console.log("getOrdersList offset is===============", offset);
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/admin/report/reported-user?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}

export const toInactiveUser = (id) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/report/user/${id}`,
     
    });
    console.log("orders list response from backend", res);
    return res;
  }
  catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
}
