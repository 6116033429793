import { combineReducers } from "redux";

import { commonReducer } from "./commonReducer";
import { authReducer } from "./authReducer";
import { dashboardReducer } from "./dashboardReducer";


const appReducer = combineReducers({
  
  commonReducer,
  authReducer,
  dashboardReducer,

});

let rootReducer = null;
export default rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
