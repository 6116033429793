import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// import Header from '../Components/Header/Header'

class PrivateGuard extends React.Component {
  dontShowAfterLogin=[
    '/',
    '/signup',
    '/signUp',
    '/login',
    '/reset-password',
    '/forgot-password'

  ]
  render() {
    const route = this.props.location?.pathname;
    // console.log("ppppp", this.props);
    let returnData = "";
    
    
    if (localStorage.getItem("adminUserId")) {
     
    if(!this.dontShowAfterLogin.includes(route)){
          returnData = <Route {...this.props} />;
     }else{
       returnData = (
     <Redirect
     to={{
         pathname: "/dashboard",
             state: { from: this.props.location },
       }}
         />
       );
     }
     
    }else{
      // returnData = <Route {...this.props} />;
      if(this.dontShowAfterLogin.includes(route)){
        returnData = <Route {...this.props} />;
      }else{
      returnData = (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
        }
        }

    return <div>{returnData}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
  };
};

export default connect(mapStateToProps)(PrivateGuard);
