import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;



export const getAd = (offset) => async (dispatch) => { 
    try {
        const res = await axios({
            
            method: "get",
            url: `${URL}/api/v1/admin/ad/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });        
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getAdDetail = (adId) => async (dispatch) => {
    console.log("inside  action" ,adId);
  
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/ad/detail/${adId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getSellersCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const searchAd = (offset, searchKeyword) => async (dispatch) => {
    console.log('445555555');
  
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/ad/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

//https://petloversbackend.appmaisters.com/api/v1/admin/ad/update-ad/122

export const updateAdDetail = (productCategoryInfo, adId) => async (dispatch) => {
    try {
        let obj = {
            is_Active : parseInt(productCategoryInfo.is_Active),
            comment : productCategoryInfo.comment,
            user_id : productCategoryInfo.user_id
        }
        console.log("SAK->",obj)
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/ad/update-ad/${adId}`,
            data: obj,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}