import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";

import {
  getSellers,
  searchSellers,
} from "../../Redux/Actions/sellersAction";


class SellersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchSellers(
            this.state.offset,
            this.state.searchKeyword.trim()
          );

        } else {

          result = await this.props.getSellers(this.state.offset);
        }
        console.log("result", result);


        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };



  handleChange = async (event, field) => {
    event.preventDefault();

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  render() {
    const itemsList = this.state.items.map((i) => {
    const date = new Date(i.created_ts)
      if (i.is_active === 0 && (i.created_ts === i.updated_ts)) {
        return (
          <tr key={i.id}>
            <td align="center">{i.id ? i.id : "-"}</td>
            <td>{i.first_name ? i.first_name : "-"}</td>
            <td>{i.last_name ? i.last_name : "-"}</td>
            <td>{i.created_ts ?  (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear()) : null}</td>


            <td>{i.is_active ? "Yes" : "No"}</td>

            <td align="center">
              <div class="vendor-table-btn">
                <Link className="orange-outline-btn mr-2" to={`edit-sellers/${i.id}`}>
                  View
                </Link>

              </div>
            </td>
          </tr>
        );

      }
      else {

        return (
          <tr key={i.id}>
            <td align="center">{i.id ? i.id : "-"}</td>
            <td>{i.first_name ? i.first_name : "-"}</td>
            <td>{i.last_name ? i.last_name : "-"}</td>
            <td>{i.created_ts ? (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear()) : null}</td>

            <td>{i.is_active ? "Yes" : "No"}</td>

            <td align="center">


              <div class="vendor-table-btn">
                <Link className="orange-outline-btn mr-2" to={`edit-sellers/${i.id}`}>
                  View
                </Link>
                <Link className="orange-outline-btn mr-2" to={`productList/${i.id}`}>
                  View  product
                </Link>
              </div>
            </td>
          </tr>
        );
      }

    });

    return (
      <div class="admin-card faq-list-page">
        <div class="admin-card-head">
          <h2>Sellers list</h2>
        </div>

        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td align="center">ID</td>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Created</td>
                        <td>Is Active</td>
                        <td align="center">View</td>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsList}
                    </tbody>
                  </table>
                </InfiniteScroll>
                {this.state.showLoader ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSellers, searchSellers }, dispatch);
};

export default connect(null, mapDispatchToProps)(SellersList);
