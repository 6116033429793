import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const getFaq = (offset) => async (dispatch) => { 
    // console.log('dfsdfdfdsfsdf');   
    try {
        const res = await axios({
            
            method: "get",
            url: `${URL}/api/v1/admin/faqs/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });        
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const getFaqDetail = (faqId) => async (dispatch) => {
  
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/faqs/detail/${faqId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}
export const deleteFaqDetail = (faqId) => async (dispatch) => {
    // console.log('4444');
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/faqs/delete/${faqId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}
export const createFaq = (productCategoryInfo) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/faqs/add`,
            data: productCategoryInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        // console.log("createProductCategory res from backend", res);

        return res;
    }
    catch (error) {
        console.log('error at createProductCategory action', error);
        dispatch(handleError(error));
    }
}



export const updateFaqDetail = (productCategoryInfo, faqId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/faqs/update-faq/${faqId}`,
            data: productCategoryInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateProductCategoryDetail response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}



export const searchFaq = (offset, searchKeyword) => async (dispatch) => {
    console.log('445555555');
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/faqs/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}