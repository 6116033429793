import React, { useEffect, useState, Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

import { debounce } from "lodash";

import {
  getSellers,
  searchSellers,
} from "../../Redux/Actions/sellersAction";

import { getReportPostList, toInactivePost } from "../../Redux/Actions/reportPost"

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchSellers(
            this.state.offset,
            this.state.searchKeyword.trim()
          );

        } else {

          result = await this.props.getReportPostList(this.state.offset);
          console.log("ressssssssssssssssssssssss",result)

        }



        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };



  handleChange = async (event, field) => {
    event.preventDefault();

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  postInactive = async (id) => {
    let result = await this.props.toInactivePost(id)
    console.log("result", result)
    if (result) {
      this.setState({

        maxLimit: 10,
        offset: 0,
        prevOffset: 0,
        items: [],
        searchKeyword: "",
        hasMore: true,
        showLoader: false,

      });
      this.fetchMoreData(true);

    }
  }

  render() {
    const itemsList = this.state.items.map((i) => {
      return (
        <tr key={i.id}>
          {console.log("itemssss",i)}
          <td>{i.userFirstName + " " + i.userLastName}</td>
          <td align="center">
            <div class="vendor-table-btn">
              <div className="modal" id={`myModalReport_${i.id}`}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">

                    <div className="modal-header">
                      <h4 className="modal-title">View Reported Post</h4>
                      <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>

                    <div className="modal-body">
                    <p className="text-left">{i.description}</p> 
                    <ul className="report-post-img">
                        {i.gallery_images != null && i.gallery_images.length > 0 && JSON.parse(i.gallery_images).map((item, index) => (
                              <li key={index}>
                                


                                <img
                                  src={item}
                                />
                              </li>
                            ))
                            }
                                                         

                        </ul>
                        


                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>

                  </div>
                </div>
              </div>
              {/* <a className="orange-outline-btn mr-2" href={`view-post/${i.id}`} target="_blank">
                  View
                </a> */}
            </div>
            <button className="orange-outline-btn"
              data-toggle="modal" data-target={`#myModalReport_${i.id}`}
            >
              View
            </button>
          </td>

          <td>
            {/* <div class="vendor-table-btn"> */}
            <button className="orange-outline-btn"
              data-toggle="modal" data-target={`#myModal_${i.id}`}
            >
              View
            </button>
            <div className="modal" id={`myModal_${i.id}`}>
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">

                  <div className="modal-header">
                    <h4 className="modal-title">Reported By</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>

                  <div className="modal-body">
                    <ul className="reported-list">
                      {/* {(JSON.parse(i.DisplayNames)).length > 3 ? JSON.parse(i.DisplayNames).map((item, index) => (
                        <li key={index}>{index <= 2 ? item : index == 3 ?
                          <div class="vendor-table-btn">
                            <Link className="orange-outline-btn mr-2" to={`/productDetails/${i.id}`}>
                              View More..
                            </Link>
                          </div> : null}</li>
                      )) :  */}
                     
                      {JSON.parse(i.DisplayNames).map((item, index) => {
                        const reportContent = (JSON.parse(i.report_description)[index]);
                        return( <li key={index}><h6>{item}</h6>
                          <span>{reportContent}</span>
                            
                          </li>);}  
                      )}
                    </ul>
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                  </div>

                </div>
              </div>
            </div>
          </td>

          <td align="center">


            <div class="vendor-table-btn">
              {i.status === '0' ?
                <button disabled={true}>
                  Inactive
                </button>
                : <button className="orange-outline-btn mr-2" onClick={() => this.postInactive(i.id)}>
                  Inactive
                </button>}
            </div>
          </td>
        </tr>
      );

    });

    return (
      <div class="admin-card faq-list-page">
        <div class="admin-card-head">
          <h2>Report Post List</h2>
        </div>

        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>

              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <td>Posted By</td>
                        <td align="center">Post</td>
                        <td>Reported By</td>
                        <td align="center">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsList}
                    </tbody>
                  </table>
                </InfiniteScroll>
                {this.state.showLoader ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSellers, searchSellers, getReportPostList, toInactivePost }, dispatch);
};

export default connect(null, mapDispatchToProps)(ReportList);


