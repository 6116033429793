import React, { Component } from 'react'
import '../../Styles/order-list.scss'
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getpagesList, searchPage } from '../../Redux/Actions/pageAction';
import { debounce } from 'lodash';

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};

class Page_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLimit: 10,
            offset: 0,
            prevOffset: 0,
            items: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }


    fetchMoreData = async (firstLoad) => {
        this.setState({
            showLoader: true
        })
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {

                let result;

                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchPage(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getpagesList(this.state.offset);
                }
                if (result?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            items: prevState.items.concat(result.data.data),
                            showLoader: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, showLoader: false });
                }
            } else {
                this.setState({
                    showLoader: false
                })
            }

        } else {
            this.setState({
                showLoader: false
            })
        }
    };


    handleChange = async (event, field) => {
        event.preventDefault();


        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,
                hasMore: true
            })

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            items: []
        })
        this.fetchMoreData(true);
    }, 1000);


    render() {
        const pageList = this.state.items.map(i => {
            const date = new Date(i.created_ts)
            const created_ts = i.created_ts ? (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear()) : null;
            return (
                <tr key={i.id}>
                    <td align="center">{i.id ? i.id : `-`}</td>
                    <td>{i.title ? i.title : `-`}</td>
                    <td>{created_ts}</td>
                    <td align="center">
                        <Link class="orange" to={`/pages/${i.id}`}>Edit</Link>
                    </td>
                </tr>
            )
        })
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>pages list</h2>
                </div>
                <div class="admin-card-box">
                    <div class="vendor-table">

                        <div class="vendor-table-head">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="entries-drop">
                                        <label>show</label>
                                        <select class="entries-controls" onChange={(e) => this.handleChange(e, "maxLimit")} value={this.state.maxLimit}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="vendor-table-head-right">
                                        <div class="vendor-table-search mr-2">
                                            <input class="form-control" onChange={(e) => this.handleChange(e, "searchKeyword")} value={this.state.searchKeyword} type="text" placeholder="search" />
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="vendor-table-body">
                            <div class="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td align="center">Id</td>
                                                <td>title</td>
                                                <td>create date</td>
                                                <td align="center">edit</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageList}
                                        </tbody>

                                    </table>
                                </InfiniteScroll>
                                {this.state.showLoader ? (
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getpagesList, searchPage }, dispatch);
};

export default connect(null, mapDispatchToProps)(Page_List)