import { isThisHour } from "date-fns";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import profileAvtar from "../../Assets/images-2/dummyUser.png";

import {
    getProductDetails,
    updatesProductDetail


} from "../../Redux/Actions/productAction";

class productDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: "",
      name: "",
      slug: "",
      price: "",
      image: [],
      availability: "",
      description: "",
      is_Active: "0",
      isEdit: false,
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: false,
      showSubmitButton: false,
    };
    const list = []

    if (this.props.match.path === "/productDetails/:productId") {
      console.log("editcheck......");
      // this.props.match.params.couponId;
      this.fetchproductDetail(this.props.match.params.productId);
    }
  }

  fetchproductDetail = async (productId) => {
    console.log("productId", productId);
    let productDetail = await this.props.getProductDetails(productId);
    console.log("sellers details", productDetail);
    if (productDetail?.data?.data?.length > 0) {
      productDetail = productDetail.data.data[0];


      
        let data = productDetail?.gallery_images
        data = JSON.parse(data);
        console.log("data",data)
 

      this.setState({
        productId: productDetail.id,
        name: productDetail.name,
        slug: productDetail.slug,
        price: productDetail.price,
        image: data,
        availability: productDetail.availability,
        is_Active: productDetail.is_active,
        description: productDetail.description,
        isEdit: true,
      });
    } else {
      console.log("dsfsdfsdsdsffdsff");
      this.props.history.push("/productList/:sellersId");
    }
  };

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    console.log("ereeeeeee", e.target.value);
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validateproductInfo = () => {
    let isproductValid = true;
    this.state.errorData = {
      name: [],
      slug: [],
      email: [],
      price: [],
      availability: [],
      image: [],
      is_Active: [],
      description: [],
      taxId: [],
    };

    if (this.state.name.trim().length === 0) {
      isproductValid = false;
      this.state.errorData.name = ["Name field cannot be empty."];
    }

    if (this.state.description.trim().length === 0) {
      isproductValid = false;
      this.state.errorData.description = [
        "product description field cannot be empty.",
      ];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isproductValid;
  };
  handleSubmit = async () => {
    await this.setState({
      successMessage: "",
      errorMessage: "",
      showLoader: true,
      showSubmitButton: true,
    });

    if (this.validateproductInfo()) {
      // faq validations passed
      let res;
      const {productId,name,slug,price,availability,description, is_Active } =
        this.state;

      if (this.state.isEdit) {  
        // call update action
        res = await this.props.updatesProductDetail(  
          {name,slug,price,availability,description,is_Active},
          productId
        );
      }
      if (res && res.data) {
        if (res.data.isSuccess) {
          // success case
          this.setState({
            successMessage: res.data.message,
            showLoader: false,
          });

          window.scrollTo(0, 0);

          if (this.state.isEdit) {
            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          } else {
            this.setState({
              productId: "",
              name: "",
              description: "",
              taxId: "0",
            });

            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          }
        } else {
          // response with failed case
          this.setState({
            errorMessage: res.data.message,
            showLoader: false,
          });
        }
      } else {
        // error to get respone
        this.setState({
          errorMessage: "Something went wrong. Please try again later.",
          showLoader: false,
        });
      }
    } else {
      // discount validations failed
      this.setState({
        showLoader: false,
      });
    }
    this.setState({ showSubmitButton: false });
  };

  render() {
  
    return (
      
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>
            {this.props.match.params.productId ? "Edit" : "Create"} Product{" "}
          </h2>
        </div>
        <div class="admin-card-box">
          <div class="row">
          <div className="col-md-8">
          <div className="row">
          <div class="col-md-6">
              <div class="form-group">
                <label>
                Name
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.name}
                  disabled={true}
                  onChange={(e) => this.handleChange(e, "name")}
                  class="form-control"
                />
                <span className="faq-field-error">
                  {this.state.errorData?.name &&
                    this.state.errorData.name[0]}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>
                  {" "}
                  Slug 
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.slug}
                  disabled={true}
                  onChange={(e) => this.handleChange(e, "slug")}
                  class="form-control"
                />
                <span className="faq-field-error">
                  {this.state.errorData?.slug &&
                    this.state.errorData.slug[0]}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>
                  {" "}
                  Price
                  <sup>*</sup>
                </label>
                <input
                  value={this.state.price}
                  disabled={true}
                  onChange={(e) => this.handleChange(e, "price")}
                  class="form-control"
                />
                <span className="faq-field-error">
                  {this.state.errorData?.price && this.state.errorData.price[0]}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>availability</label>
                <textarea
                  value={this.state.availability}
                  disabled={true}
                  onChange={(e) => this.handleChange(e, "availability")}
                  type="text"
                  class="form-control"
                >
                  {this.state.availability}
                </textarea>
                <span className="faq-field-error">
                  {this.state.errorData?.availability &&
                    this.state.errorData.availability[0]}
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>
                  Description
                  <sup>*</sup>
                </label>
                <textarea
                  value={this.state.description}
                  disabled={true}
                  onChange={(e) => this.handleChange(e, "description")}
                  type="text"
                  class="form-control"
                >
                  {this.state.description}
                </textarea>
                <span className="faq-field-error">
                  {this.state.errorData?.description &&
                    this.state.errorData.description[0]}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Is Active?</label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    Inactive
                    <span>
                      <input
                        type="radio"
                        value="0"
                        onChange={(e) => this.handleChange(e, "is_Active")}
                        checked={this.state.is_Active == 0}
                        name="radio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>

                  <p className="container-sub mr-2">
                    Active
                    <span>
                      <input
                        type="radio"
                        value="1"
                        onChange={(e) => this.handleChange(e, "is_Active")}
                        checked={this.state.is_Active == 1}
                        name="radio"
                      />
                    </span>
                    <span class="checkmark"></span>
                  </p>
                </div>
              </div>
            </div>


            <div class="col-md-12">
              <div class="faq-btn text-center">
                <button
                  type="button"
                  disabled={this.state.showSubmitButton}
                  onClick={this.handleSubmit}
                  class="orange-outline-btn"
                >
                  submit
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span class="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
              </div>
            </div>
            </div>
          </div>
          <div className="col-md-4">
              <div className="profile-picture text-center">
                <div className="profile-picture-head">
                  <h3>Product Pictures</h3>
                </div>
                <div className="user-profile-img">

                <ul>
                  {this.state.image.map((i)=>{
                return(
                  <li>
                  <img
                    src={
                      i
                        ? i
                        : profileAvtar
                    }
                    alt="images"
                  />
                  </li>
                )
              })}
                </ul>   
                </div>
              </div>
          {(() => {
            if (
              this.state.successMessage !== "" &&
              this.state.successMessage !== undefined
            ) {
              return (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              );
            } else if (
              this.state.errorMessage !== "" &&
              this.state.errorMessage !== undefined
            ) {
              return (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              );
            }
          })()}
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getProductDetails,updatesProductDetail },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(productDetail);
