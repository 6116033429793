import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePassword } from '../../Redux/Actions/userAction';

class Change_Password extends React.Component {

    userData = localStorage.getItem('adminUserData') ? JSON.parse(localStorage.getItem('adminUserData')) : null;

    constructor(props) {
        super(props)

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            successMessage: "",
            errorData: {},
            errorMessage: "",
            success: null,
            showLoader: false,
        }
    }

    handleChange = (event) => {
        if (
            this.state.errorData &&
            this.state.errorData[event.target.name] &&
            this.state.errorData[event.target.name][0]
        ) {
            this.state.errorData[event.target.name][0] = null;
        }


        this.setState({
            [event.target.name]: event.target.value
        })
    }

    validateUserInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            "oldPassword": [],
            "newPassword": [],
            "confirmNewPassword": []
        }

        if (this.state.oldPassword.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.oldPassword = ["Old Password is required."]
        }

        if (this.state.newPassword.trim().length < 6) {
            isFormValid = false;
            this.state.errorData.newPassword = ["New Password should be at least 6 characters."]
        }

        if (this.state.newPassword.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.newPassword = ["New Password field cannot be empty."]
        }

        if (this.state.newPassword.trim() !== this.state.confirmNewPassword.trim()) {
            isFormValid = false;
            this.state.errorData.confirmNewPassword = ["Password confirmation does not match password."]
        }

        if (this.state.confirmNewPassword.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.confirmNewPassword = ["Confirm New Password field cannot be empty."]
        }

        let tempState = this.state;

        this.setState({
            ...tempState
        })

        return isFormValid;
    }

    async handleSubmit(event) {
        event.preventDefault();

        await this.setState({
            successMessage: '',
            errorMessage: '',
            errorData: {},
            showLoader: true
        });

        if (this.validateUserInfo()) {
            const res = await this.props.changePassword(this.userData ?.email, this.state.oldPassword, this.state.newPassword, this.state.confirmNewPassword);
            console.log(res, 'res');
            if (res !== undefined) {
                if (res.data.success) {
                    this.setState({
                        oldPassword: '',
                        newPassword: '',
                        confirmNewPassword: '',
                        errorMessage: "",
                        successMessage: res.data.message,
                        success: res.data.success,
                        showLoader: false
                    });
                }
                else {
                    this.setState({
                        errorMessage: res.data.message,
                        successMessage: "",
                        success: res.data.success,
                        showLoader: false
                    })
                }
            } else {
                console.log(this.props.errorData, 'this.props.errorData');
                this.setState({
                    errorData: this.props.errorData,
                    errorMessage: "Something went wrong! please check input fields",
                    showLoader: false
                });

            }
        }
        else {
            this.setState({
                showLoader: false
            });
        }
    }

    render() {
        // console.log("state", this.state.imagename);
        return (
            <div>
                <main className="mainContainer">
            <div className="container">
                        <div className="signup">
                    
                    <div className="signup-head">
                    <h2>Change Password</h2>

                <p>ENTER NEW PASSWORD AND CONFIRM PASSWORD TO CHANGE YOUR PASSWORD.</p>
                 </div>
                {(() => {
                    if (this.state.successMessage !== '' && this.state.success) {
                        return <div className="alert alert-success">{this.state.successMessage}</div>
                    } else if (this.state.errorMessage !== '') {
                        return <div className="alert alert-danger">{this.state.errorMessage}</div>
                    }
                })()}

                <div className="admin-card-box">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="profile-form">
                                    <form>
                                        <div className="form-group">
                                            <input type="password" name="oldPassword" className="form-control"
                                                placeholder='OLD PASSWORD' value={this.state.oldPassword} onChange={this.handleChange}
                                            />
                                            <span className="form-field-error" style={{ color: "red", fontSize: "12px" }}>
                                                {this.state.errorData ?.oldPassword &&
                                                    this.state.errorData.oldPassword[0]}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="newPassword" className="form-control"
                                                placeholder='NEW PASSWORD' value={this.state.newPassword} onChange={this.handleChange}
                                            />
                                            <span className="form-field-error" style={{ color: "red", fontSize: "12px" }}>
                                                {this.state.errorData ?.newPassword &&
                                                    this.state.errorData.newPassword[0]}
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="confirmNewPassword" className="form-control"
                                                placeholder='CONFIRM PASSWORD' value={this.state.confirmNewPassword} onChange={this.handleChange}
                                            />
                                            <span className="form-field-error" style={{ color: "red", fontSize: "12px" }}>
                                                {this.state.errorData ?.confirmNewPassword &&
                                                    this.state.errorData.confirmNewPassword[0]}
                                            </span>
                                        </div>
                                    </form>
                                    </div>
                                    <div className="signup-bottom">
                                    <div className="signup-submit">
                                    <button onClick={(event) => this.handleSubmit(event)} type="button" className="orange-outline-btn">Change Password
                                                            {(() => {
                                            if (this.state.showLoader) {
                                                return <span class="spinner-border spinner-border-sm ml-1"></span>
                                            }
                                        })()}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        errorData: state.commonReducer.errorData,
        // authData: state.userReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ changePassword }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Change_Password);