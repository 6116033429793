import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import profileAvtar from "../../Assets/images-2/dummyUser.png";

import {
  getSellersDetail,
  updateSellersDetail,
} from "../../Redux/Actions/sellersAction";

class Edit_Sellers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sellersId: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      image: "",
      address: "",
      taxId: "",
      description: "",
      is_Active: "0",
      isEdit: false,
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: false,
      showSubmitButton: false,
    };

    if (this.props.match.path === "/edit-sellers/:sellersId") {
      console.log("editcheck......");
      // this.props.match.params.couponId;
      this.fetchSellersDetail(this.props.match.params.sellersId);
    }
  }

  fetchSellersDetail = async (sellersId) => {
    console.log("sellersId", sellersId);
    let sellersDetail = await this.props.getSellersDetail(sellersId);
    console.log("sellers details", sellersDetail);
    if (sellersDetail?.data?.data?.length > 0) {
      sellersDetail = sellersDetail.data.data[0];

      this.setState({
        sellersId: sellersDetail.id,
        firstname: sellersDetail.first_name,
        lastname: sellersDetail.last_name,
        email: sellersDetail.email,
        phone: sellersDetail.phone_number,
        image: sellersDetail.image,
        address: sellersDetail.address,
        taxId: sellersDetail.tax_id,
        is_Active: sellersDetail.is_active,
        description: sellersDetail.description,
        isEdit: true,
      });
    } else {
      console.log("dsfsdfsdsdsffdsff");
      this.props.history.push("/sellers");
    }
  };

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    console.log("ereeeeeee", e.target.value);
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validatefaqInfo = () => {
    let isFaqValid = true;
    this.state.errorData = {
      firstname: [],
      lastname: [],
      email: [],
      phone: [],
      address: [],
      image: [],
      is_Active: [],
      description: [],
      taxId: [],
    };

    if (this.state.firstname.trim().length === 0) {
      isFaqValid = false;
      this.state.errorData.firstname = ["Name field cannot be empty."];
    }

    if (this.state.description.trim().length === 0) {
      isFaqValid = false;
      this.state.errorData.description = [
        "Sellers description field cannot be empty.",
      ];
    }

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFaqValid;
  };
  handleSubmit = async () => {
    await this.setState({
      successMessage: "",
      errorMessage: "",
      showLoader: true,
      showSubmitButton: true,
    });

    if (this.validatefaqInfo()) {
      // faq validations passed
      let res;
      const { sellersId, lastname, email, phone, firstname, address, taxId, description, is_Active, image } =
        this.state;

      if (this.state.isEdit) {
        // call update action
        res = await this.props.updateSellersDetail(
          { firstname, lastname, email, phone, image, address, description, taxId, is_Active, sellersId, },
          sellersId
        );
      }
      if (res && res.data) {
        if (res.data.isSuccess) {
          // success case
          this.setState({
            successMessage: res.data.message,
            showLoader: false,
          });

          window.scrollTo(0, 0);

          if (this.state.isEdit) {
            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          } else {
            this.setState({
              sellersId: "",
              firstname: "",
              description: "",
              taxId: "0",
            });

            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          }
        } else {
          // response with failed case
          this.setState({
            errorMessage: res.data.message,
            showLoader: false,
          });
        }
      } else {
        // error to get respone
        this.setState({
          errorMessage: "Something went wrong. Please try again later.",
          showLoader: false,
        });
      }
    } else {
      // discount validations failed
      this.setState({
        showLoader: false,
      });
    }
    this.setState({ showSubmitButton: false });
  };

  render() {
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>
            {this.props.match.params.sellersId ? "Edit" : "Create"} Seller{" "}
          </h2>
        </div>
        <div class="admin-card-box">
          <div class="row">
            <div className="col-md-8">
              <div className="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      First Name
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.firstname}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "firstname")}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.firstname &&
                        this.state.errorData.firstname[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {" "}
                      Last Name
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.lastname}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "lastname")}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.lastname &&
                        this.state.errorData.lastname[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {" "}
                      Email
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.email}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "email")}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.email && this.state.errorData.email[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {" "}
                      Phone Number
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.phone}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "phone")}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.phone && this.state.errorData.phone[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Address</label>
                    <textarea
                      value={this.state.address}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "address")}
                      type="text"
                      class="form-control"
                    >
                      {this.state.address}
                    </textarea>
                    <span className="faq-field-error">
                      {this.state.errorData?.address &&
                        this.state.errorData.address[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Description
                      <sup>*</sup>
                    </label>
                    <textarea
                      value={this.state.description}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "description")}
                      type="text"
                      class="form-control"
                    >
                      {this.state.description}
                    </textarea>
                    <span className="faq-field-error">
                      {this.state.errorData?.description &&
                        this.state.errorData.description[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label> Tax Id</label>
                    <input
                      value={this.state.taxId}
                      disabled={true}
                      onChange={(e) => this.handleChange(e, "taxId")}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.taxId && this.state.errorData.taxId[0]}
                    </span>
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label>Is Active?</label>
                    <div className="custom-radio-input">
                      <p className="container-sub mr-2">
                        Inactive
                        <span>
                          <input
                            type="radio"
                            value="0"
                            onChange={(e) => this.handleChange(e, "is_Active")}
                            checked={this.state.is_Active == 0}
                            name="radio"
                          />
                        </span>
                        <span class="checkmark"></span>
                      </p>

                      <p className="container-sub mr-2">
                        Active
                        <span>
                          <input
                            type="radio"
                            value="1"
                            onChange={(e) => this.handleChange(e, "is_Active")}
                            checked={this.state.is_Active == 1}
                            name="radio"
                          />
                        </span>
                        <span class="checkmark"></span>
                      </p>
                    </div>
                  </div>
                </div>


                <div class="col-md-12">
                  <div class="faq-btn text-center">
                    <button
                      type="button"
                      disabled={this.state.showSubmitButton}
                      onClick={this.handleSubmit}
                      class="orange-outline-btn"
                    >
                      submit
                      {(() => {
                        if (this.state?.showLoader) {
                          return (
                            <span class="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="profile-picture text-center">
                <div className="profile-picture-head">
                  <h3>Profile Picture</h3>
                </div>
                <div className="user-profile-img">
                  {console.log("image", this.state.image)}
                  <img
                    className="profile-img"
                    src={
                      this.state.image
                        ? this.state.image
                        : profileAvtar
                    }

                    alt="images"
                  />
                </div>
              </div>
              {(() => {
                if (
                  this.state.successMessage !== "" &&
                  this.state.successMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-success">
                      {this.state.successMessage}
                    </div>
                  );
                } else if (
                  this.state.errorMessage !== "" &&
                  this.state.errorMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-danger">
                      {this.state.errorMessage}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSellersDetail, updateSellersDetail },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Edit_Sellers);
