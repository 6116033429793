export const initialState = {
  isRegistered: false,
  isEmailVerified: "",
  isLoggedIn: false,
  authData: {},
};

export const authReducer = (state = initialState, action) => {
  // console.log(action,'action');
  switch (action.type) {
    case "SIGNUP":
      let code='';
      if(action.payload.data.data.data.code!== undefined){
        code = action.payload.data.data.data.code
      }
      return {
        ...state,
        isRegistered: action.payload.data.isRegistered,
        code
      };
    case "EMAILVERIFICATION":
      return {
        ...state,
        isEmailVerified: action.payload.data.message,
        isRegistered: "",
      };
    case "LOGIN":
      console.log("userdate",JSON.stringify(action.payload?.data?.user))
      localStorage.setItem("userData",JSON.stringify(action.payload?.data?.user))
      return {
        ...state,
        isLoggedIn: true,
        authData: action.payload.data.user
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        isRegistered: "",
        isEmailVerified: "",
        authData: {},
      };
    case "UPDATEUSERSETTINGS":
      return {
        ...state,
        isUpdated: action.payload.data.message,
        authData: {
          ...state.authData,
          ...action.payload.data,
        },
      };
      case "ERROR":
      return {
        ...state,
        isRegistered: false,
        code: ''
      };
    default:
      return state;
  }
};
