import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import profileAvtar from "../../Assets/images-2/dummyUser.png";

import { getAdDetail, updateAdDetail } from "../../Redux/Actions/adAction";

class adDetail extends Component {
  constructor(props) {
    super(props);

    var today = new Date(),
      date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
    this.state = {
      sellersId: "",
      company_name: "",
      company_website_url: "",
      email: "",
      phone: "",
      image: [],
      address: "",
      region: "",
      account_balance: "0",
      is_Active: "0",
      isEdit: false,
      successMessage: "",
      errorMessage: "",
      date: date,
      admin_comments: [{ date: "", comment: "" }],
      errorData: {},
      showLoader: false,
      showSubmitButton: false,
      comment: "",
    };

    if (this.props.match.path === "/edit-ad/:adId") {
      this.fetchAdDetail(this.props.match.params.adId);
    }
  }

  fetchAdDetail = async (adId) => {
    let adDetail = await this.props.getAdDetail(adId);
    if (adDetail?.data?.data?.length > 0) {
      adDetail = adDetail.data.data[0];

      let data = adDetail?.image;
      data = JSON.parse(data);

      this.setState({
        adId: adDetail.id,
        company_name: adDetail.company_name,
        company_website_url: adDetail.company_website_url,
        email: adDetail.email,
        phone: adDetail.phone_number,
        image: data,
        address: adDetail.address,
        region: adDetail.region,
        account_balance: adDetail.account_balance,
        is_Active: adDetail.is_active,
        description: adDetail.description,
        isEdit: true,
        user_id:adDetail.user_id,
        admin_comments: adDetail?.admin_comments
          ? JSON.parse(adDetail.admin_comments)
          : null,
      });
    } else {
      this.props.history.push("/ad");
    }
  };

  handleChange = (e, key) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  validatefaqInfo = () => {
    let isFaqValid = true;
    this.state.errorData = {
      company_name: [],
      company_website_url: [],
      email: [],
      phone: [],
      address: [],
      image: [],
      is_Active: [],
      region: [],
    };

    let tempState = this.state;
    this.setState({
      ...tempState,
    });

    return isFaqValid;
  };

  handleSubmit = async () => {
    await this.setState({
      successMessage: "",
      errorMessage: "",
      showLoader: true,
      showSubmitButton: true,
    });

    if (this.validatefaqInfo()) {
      // faq validations passed
      let res;
      const {
        adId,
        email,
        phone,
        company_name,
        address,
        company_website_url,
        region,
        account_balance,
        is_Active,
        image,
        admin_comments,
        user_id
      } = this.state;

      if (this.state.isEdit) {
        let comment = this.state.admin_comments;

        if (!Array.isArray(comment)) {
            comment = [];
        }
        
        comment.push({ date: this.state.date, comment: this.state.comment });

        // call update action
        res = await this.props.updateAdDetail(
          {
            company_name,
            email,
            phone,
            image,
            address,
            company_website_url,
            region,
            account_balance,
            is_Active,
            adId,
            comment,
            user_id
          },
          adId
        );
      }
      if (res && res.data) {
        if (res.data.isSuccess) {
          // success case
          this.setState({
            successMessage: res.data.message,
            showLoader: false,
            comment: "",
          });

          window.scrollTo(0, 0);

          if (this.state.isEdit) {
            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          } else {
            this.setState({
              adId: "",
              company_name: "",
            });

            setTimeout(() => {
              this.setState({
                successMessage: "",
              });
            }, 4000);
          }
        } else {
          // response with failed case
          this.setState({
            errorMessage: res.data.message,
            showLoader: false,
          });
        }
      } else {
        // error to get responeds
        this.setState({
          errorMessage: "Something went wrong. Please try again later.",
          showLoader: false,
        });
      }
    } else {
      // discount validations failed
      this.setState({
        showLoader: false,
      });
    }
    this.setState({ showSubmitButton: false });
  };

  render() {
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>{this.props.match.params.adId ? "Edit" : "Create"} Ad </h2>
        </div>
        <div class="admin-card-box">
          <div class="row">
            <div className="col-md-8">
              <div className="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Company Name
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.company_name}
                      disabled={true}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.company_name &&
                        this.state.errorData.company_name[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Company Website Url
                      <sup>*</sup>
                    </label>
                    <a href={"http://" + this.state.company_website_url}>
                      <input
                        value={this.state.company_website_url}
                        disabled={true}
                        class="form-control"
                      />
                    </a>
                    <span className="faq-field-error">
                      {this.state.errorData?.company_website_url &&
                        this.state.errorData.company_website_url[0]}
                    </span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {" "}
                      Email
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.email}
                      disabled={true}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.email &&
                        this.state.errorData.email[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      {" "}
                      Phone Number
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.phone}
                      disabled={true}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.phone &&
                        this.state.errorData.phone[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Address</label>
                    <textarea
                      value={this.state.address}
                      disabled={true}
                      type="text"
                      class="form-control"
                    >
                      {this.state.address}
                    </textarea>
                    <span className="faq-field-error">
                      {this.state.errorData?.address &&
                        this.state.errorData.address[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>
                      Account Balance
                      <sup>*</sup>
                    </label>
                    <input
                      value={this.state.account_balance}
                      disabled={true}
                      type="text"
                      class="form-control"
                    />

                    <span className="faq-field-error">
                      {this.state.errorData?.account_balance &&
                        this.state.errorData.account_balance[0]}
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Region</label>
                    <input
                      value={this.state.region}
                      disabled={true}
                      class="form-control"
                    />
                    <span className="faq-field-error">
                      {this.state.errorData?.taxId &&
                        this.state.errorData.region[0]}
                    </span>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Is Active?</label>
                    <div className="custom-radio-input">
                      <p className="container-sub mr-2">
                        Inactive
                        <span>
                          <input
                            type="radio"
                            value="0"
                            onChange={(e) => this.handleChange(e, "is_Active")}
                            checked={this.state.is_Active == 0}
                            name="radio"
                          />
                        </span>
                        <span class="checkmark"></span>
                      </p>

                      <p className="container-sub mr-2">
                        Active
                        <span>
                          <input
                            type="radio"
                            value="1"
                            onChange={(e) => this.handleChange(e, "is_Active")}
                            checked={this.state.is_Active == 1}
                            name="radio"
                          />
                        </span>
                        <span class="checkmark"></span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    {this.state.is_Active == 0 ? (
                      <>
                        <label>Comment:</label>
                        <textarea
                          value={this.state.comment}
                          onChange={(e) => this.handleChange(e, "comment")}
                          className="form-control"
                          rows="12"
                        />
                      </>
                    ) : null}

                    <span className="faq-field-error">
                      {this.state.errorData?.comment &&
                        this.state.errorData.comment[0]}
                    </span>
                  </div>
                </div>

                <label>Comment list:</label>
               
                {Array.isArray(this.state.admin_comments) && this.state.admin_comments.length > 0 && (
  this.state.admin_comments.map((admin_comment, index) => (
    <div key={index}>
      <label>{admin_comment.date}</label>
      <label>{admin_comment.comment}</label>
    </div>
  ))
)}

                <div class="col-md-12">
                  <div class="faq-btn text-center">
                    <button
                      type="button"
                      disabled={this.state.showSubmitButton}
                      onClick={this.handleSubmit}
                      class="orange-outline-btn"
                    >
                      submit
                      {(() => {
                        if (this.state?.showLoader) {
                          return (
                            <span class="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="profile-picture text-center">
                <div className="profile-picture-head">
                  <h3>Picture</h3>
                </div>
                <div className="profile-custom-set">
                  {this.state.image.map((i) => {
                    return <img src={i ? i : profileAvtar} alt="images" />;
                  })}

                  {/* <img
                    className="profile-img"
                    src={
                      this.state.image
                        ? this.state.image
                        : profileAvtar
                    }

                    alt="images"
                  /> */}
                </div>
              </div>
              {(() => {
                if (
                  this.state.successMessage !== "" &&
                  this.state.successMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-success">
                      {this.state.successMessage}
                    </div>
                  );
                } else if (
                  this.state.errorMessage !== "" &&
                  this.state.errorMessage !== undefined
                ) {
                  return (
                    <div className="alert alert-danger">
                      {this.state.errorMessage}
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getAdDetail, updateAdDetail }, dispatch);
};

export default connect(null, mapDispatchToProps)(adDetail);
