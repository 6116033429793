import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const signup = ({
  firstname,
  lastname,
  email,
  password,
  confirmPassword,
  isacceptedtnc,
  newsletter,
}) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/users/signup`,
      data: {
        firstname,
        lastname,
        email,
        password,
        confirmPassword,
        isacceptedtnc,
        newsletter
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "SIGNUP",
      payload: { data: res.data },
    });
  } catch (error) {
    console.log('wrong', error);
    dispatch(handleError(error));
  }
};

export const vemail = ({ code }) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "patch",
      url: `${URL}/api/v1/users/verifyCode`,
      data: {
        code,
        userId: localStorage.getItem("adminUserId"),
        companyId: localStorage.getItem("companyId"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "EMAILVERIFICATION",
      payload: { data: res.data },
    });
    localStorage.clear();
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const login = ({ email, password }) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/login`,
      data: {
        email,
        password,
      },
    });
    console.log("LOGINNN",res)
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });

    dispatch({
      type: "SETUSERINFO",
      payload: res.data.user,
    });

  } catch (error) {
    dispatch(handleError(error));
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateUserSettings = ({
  userId,
  companyId,
  name,
  image,
}) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "patch",
      url: `${URL}/api/v1/companies/${companyId}/users/${userId}/profile`,
      data: { name, image },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    // console.log("settings", res.data);
    dispatch({
      type: "UPDATEUSERSETTINGS",
      payload: { data: { name, image } },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const matchtoken = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/users/matchtoken?companyId=${localStorage.getItem(
        "companyId"
      )}&userId=${localStorage.getItem("adminUserId")}&token=${localStorage.getItem(
        "token"
      )}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "LOGIN",
      payload: { data: res.data },
    });
  } catch (error) {
    // console.log({ error });
    dispatch({ type: "LOADING_FAILURE" });
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch({
          type: "ERROR",
          payload: { error: error.response.data },
        });
      }

    } else { }
  }
};


export const updateProfile = (data) => async (dispatch) => {
  // console.log(`update profile`, data)
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData()
    for (var key in data) {
      formData.append(key, data[key]);
    }
    // formData.append("selectedImg", data.selectedImg);
    // formData.append("lastname", data.lastname);

    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/users/${data.id}/updateProfile`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    });
    dispatch({
      type: "SETUSERINFO",
      payload: res.data.data,
    });
    return res;
  } catch (error) {
    return error

  }
}

export const getUserDetail = (data) => async (dispatch) => {
 console.log(`update profile`, data)
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: 'get',
      url: `${URL}/api/v1/users/${data.id}`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}


export const logoutUser = () => async (dispatch) => {
  // console.log(`update profile`)
  try {

    const rememberme = localStorage.getItem("rememberme");
    localStorage.clear();
    if (rememberme) {
      localStorage.setItem("rememberme", rememberme);
    }

    dispatch({ type: "LOGOUT" });

  } catch (error) {


  }
}



export const forgotPassword = (email) => async (dispatch) => {
  try {
    console.log("forgotPassword paramsssssssss", email);
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/forgotPassword`,
      data: {
        email
      }
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
}

export const resetPassword = (newPassword, confirmNewPassword, resetKey) => async (dispatch) => {
  try {
    // console.log("ResetPassword paramsssssssss", newPassword);
    // console.log("ResetPassword paramsssssssss", confirmNewPassword);
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/admin/resetPassword`,
      data: {
        newPassword,
        confirmNewPassword,
        resetKey
      }
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;

  } catch (error) {
    dispatch(handleError(error));
  }
}