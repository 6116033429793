import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getPetofdayList = (offset, limit = 10) => async (dispatch) => {
    console.log("getOrdersList offset is===============", offset);
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/petofdate/users?offset=${offset}&limit=${limit}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
      console.log("orders list response from backend", res);
      return res;
    }
    catch (error) {
      console.log('wrong', error);
      dispatch(handleError(error));
    }
  }
  export const getPetDate=()=> async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/admin/petofdate/date`,
      });
      dispatch({ type: "LOADING_COMPLETED" });
  
      return res.data;
    }
    catch (error) {
      dispatch(handleError(error));
    }
  }
  export const removePetofDay=(id)=> async (dispatch) => {
      console.log("idssssss",id)
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "delete",
        url: `${URL}/api/v1/admin/petofdate/remove/${id}`,
      });
      dispatch({ type: "LOADING_COMPLETED" });
  
      return res.data;
    }
    catch (error) {
      dispatch(handleError(error));
    }
  }

  export const addpetofday = (data) => async (dispatch) => {
    // console.log(`update profile`, data)
    const {id}=data.profile;
    // const active=isactive
    console.log('dbcdnbcjn',id);
    try {
      dispatch({ type: "LOADING_STARTED" });
      let formData = new FormData()
      for (var key in data) {
        formData.append(key, data[key]);
      }
      // formData.append("selectedImg", data.selectedImg);
      // formData.append("lastname", data.lastname);
  
      const res = await axios({
        method: 'post',
        url: `${URL}/api/v1/admin/petofdate/add/${id}`,
        data: data,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
      });
        console.log("dvhfdvhfbhv",res);
      return res;
    } catch (error) {
      return error
    }
  }