
import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const getProductList = (sellersId) => async (dispatch) => {
    console.log("inside sellers action" ,sellersId);
  
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/products/list/${sellersId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getproduct response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}


export const getProductDetails = (productId) => async (dispatch) => {
    console.log("inside sellers action" ,productId);
  
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/products/detail/${productId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("getSellersCategoryDetails response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const updatesProductDetail = (productCategoryInfo, productId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "post",
            url: `${URL}/api/v1/admin/products/update-product/${productId}`,
            data: productCategoryInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        console.log("updateProductCategoryDetails response", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const searchProducts = ( searchKeyword) => async (dispatch) => {
    console.log('445555555');
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/products/by/search?search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

