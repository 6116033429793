import React, { Component } from "react";
import "../../Styles/order-list.scss";
import Header from "../../Components/Header/header";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { searchUser} from "../../Redux/Actions/userAction";
import {
  getPetDate,
  getPetofdayList,
  removePetofDay,
  addpetofday,
} from "../../Redux/Actions/petofDayAction";
import { debounce } from "lodash";
import defaultUserprofile from "../../Assets/Images/defaultUserprofile.png";
import moment from 'moment';

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
console.log("jgjffjenfdk");
class PetofDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      users: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      update: false,
      deleteId:0,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  
  fetchMoreData = async (firstLoad) => {
    this.setState({
        showLoader: true
    })
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
        if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
            let result;
            if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                result = await this.props.searchUser(this.state.offset, this.state.searchKeyword.trim());
            }
            else {
              console.log("dvgvdfc");

                result = await this.props.getPetofdayList(this.state.offset);
            }

            // let result = await this.props.getUsersList(this.state.offset);
            if (result ?.data ?.data ?.length > 0) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        prevOffset: prevState.offset,
                        offset: prevState.offset + 10,
                        users: prevState.users.concat(result.data.data),
                        showLoader: false
                    }
                })
            }
            else {
                this.setState({ hasMore: false, showLoader: false });
            }
        }else {
            this.setState({
                showLoader: false
            })
        }
    }else {
        this.setState({
            showLoader: false
        })
    }
};

  remove = async () => {
    console.log("removedfgfgf===================================",this.state.deleteId);
    const result = await this.props.removePetofDay(this.state.deleteId);
    console.log("resultssssss", result);
    if (result.isError == false) {
     
      this.setState((prevState) => {
        return {
          ...prevState,
          update: true,
        };
        
      });
      window.location.reload();
      this.fetchMoreData(true);
      console.log("updates",this.state.update);
    
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    // console.log("event value is", +event.target.value);

    if (field === "maxLimit") {
        await this.setState({
            [field]: event.target.value === "all" ? event.target.value : +event.target.value
        })
        this.fetchMoreData(false);
    }
    else {
        await this.setState({
            [field]: event.target.value,
            offset: 0,
            prevOffset: 0,                
            hasMore: true
        })

        this.debounceSearchCall();
    }
};

  debounceSearchCall = debounce(() => {
    this.setState({
        users: []
    })
    this.fetchMoreData(true);
}, 1000);   

  render() {
    const usersList = this.state.users.map((i,index) => {
     

      // const fullAddress = i.address ? JSON.parse(i.address) : null;
      const profileLink = i.UserId
        ? `http://petlovers-customer.s3-website-us-west-2.amazonaws.com/profile/${i.UserId}`
        : "http://petlovers-customer.s3-website-us-west-2.amazonaws.com/profile";

      return (
        <tr key={index}>
          {/* <td key={i.id} align="center">{i.id ? i.id : "-"}</td> */}
          <td  className="d-flex align-items-center">
            <div className="user-pet-pic">
              <img alt="-" src={i?.profile_image ? i.profile_image : defaultUserprofile} />
            </div>
            {i.first_name ? i.first_name + " " + i.last_name : "-"}
          </td>

          <td >
            <a href={profileLink} target="_blank">
              View Profile
            </a>
          </td>
          {/* <td>{i.lastname ? i.lastname : "-"}</td> */}
          {/* <td>{ <img
                    className="w-80"
                          alt="-"
                          src={
                            i?.profile_image
                              ? i.profile_image
                              : "-"
                          }
                         
                        />}</td> */}
          <td>{i.date ? moment(i.date).format("MM-DD-YYYY") : null}</td>
          <td >
            {/* onClick={()=>this.remove(i.id)} */}
            <i
              class="far fa-trash-alt"
              data-toggle="modal"
              data-target="#myModal1"
              onClick={() =>this.setState({deleteId:i.id})}
              
              
              
            ></i>

          </td>
          
          <div class="modal" id="myModal1">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">Delete</h4>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
               
                  <div class="modal-body">
                    <h6>Are you sure you want to delete?</h6>
                    <div className="d-flex align-items-center">
                      <button className="orange-outline-btn mr-3" type="button" data-dismiss="modal">No</button>
                      <button className="orange-btn" data-dismiss="modal" onClick={()=>this.remove()}>Yes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </tr>
        
      );
    });
    return (
      <div class="admin-card">
        <div class="admin-card-head">
          <h2>Users List</h2>
        </div>
        <div class="admin-card-box">
          <div class="vendor-table">
            <div class="vendor-table-head">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="entries-drop">
                    <label>show</label>
                    <select
                      class="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="vendor-table-head-right">
                    <div class="vendor-table-search mr-2">
                      <input
                        class="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vendor-table-body">
              <div class="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.users.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        {/* <td align="center">User ID.</td> */}
                        <td>Name</td>
                        <td>Profile</td>
                        <td>Date</td>
                        <td>Remove</td>
                      </tr>
                    </thead>
                    <tbody>{usersList}</tbody>
                  </table>
                </InfiniteScroll>
                {this.state.showLoader ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getPetofdayList, searchUser, getPetDate, removePetofDay, addpetofday },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(PetofDate);
