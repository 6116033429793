import React, { Component } from "react";
import "../../Styles/sidebar.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleSidebar } from "../../Redux/Actions/commonAction";
import { logoutUser } from "../../Redux/Actions/authAction";
// import { getProductListCountForApproval } from '../../Redux/Actions/productAction';
import Logo from "../../Assets/Images/logo.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowSidebar: false,
    };
    // console.log("Inside sidebar constructor", props);
  }

  componentDidMount() {
    // this.props.getProductListCountForApproval();
  }

  handleLogout = async (event) => {
    console.log("events", event);
    event.preventDefault();
    this.props.logoutUser();
  };

  componentWillReceiveProps(nextProps) {
    console.log("nfhvfv", nextProps);
    if (nextProps.isShowSidebar !== this.props.isShowSidebar) {
      this.setState({
        isShowSidebar: nextProps.isShowSidebar,
      });
    }
  }

  render() {
    return (
      <>
        <div
          class={this.props.isShowSidebar ? "sidebar sidebar-show" : "sidebar"}
        >
          <div class="sidebar-head">
            <img src={Logo} alt="logo" />
            <div class="toggle-close-btn">
              <button onClick={() => this.props.toggleSidebar()} type="button">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div class="sidebar-menu">
            <ul>
              <li>
                <Link
                  to={"/dashboard"}
                  className={
                    this.props.location.pathname === "/dashboard"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-home"></i> dashboard
                </Link>
              </li>
              {/* <li><Link to={'/orders'} className={this.props.location.pathname === '/orders' || this.props.match.path == '/orders/:orderId' || this.props.match.path == '/orders/track/:trackId' ? 'active' : 'null'}><i class="fas fa-history"></i> orders list</Link></li> */}
              <li>
                <Link
                  to={"/users"}
                  className={
                    this.props.location.pathname === "/users" ||
                    this.props.match.path == "/users/:userId"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list"></i> users list
                </Link>
              </li>
              <li>
                <Link
                  to={"/petDay"}
                  className={
                    this.props.location.pathname === "/petDay"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list" />
                  Pet of The Day
                </Link>
              </li>

              <li>
                <Link
                  to={"/faqs"}
                  className={
                    this.props.location.pathname === "/faqs" ||
                    this.props.location.pathname == "/create-faq" ||
                    this.props.match.path == "/edit-faq/:faqId"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list"></i> FAQs
                </Link>
              </li>

              {/* <li><Link to={'/payments'} className={this.props.location.pathname === '/payments' ? 'active' : 'null'}><i class="fas fa-list"></i> Payments List</Link></li> */}
              {/* <li><Link to={'/pages'} className={this.props.location.pathname === '/pages' || this.props.match.path == '/pages/:pageId' ? 'active' : 'null'}><i class="fas fa-list"></i> Pages List</Link></li> */}
              {/* <li><Link to={'/coupon'} className={this.props.location.pathname === '/coupon' || this.props.match.path == '/create-coupon' || this.props.match.path == '/edit-coupon/:couponId' ? 'active' : 'null'}><i class="fas fa-list"></i> coupons list</Link></li> */}

              {/* <li><Link to={'/product-category'} className={this.props.location.pathname === '/product-category' || this.props.match.path == '/create-product-category' || this.props.match.path == '/edit-product-category/:categoryId' ? 'active' : 'null'}><i class="fas fa-list"></i> category list</Link></li> */}
              {/* <li><Link to={'/product'} className={this.props.location.pathname === '/product' || this.props.match.path == '/create-product-category' || this.props.match.path == '/product' ? 'active' : 'null'}><i class="fas fa-list"></i> product list</Link></li> */}
              {/* <li><Link to={'/report-management'} className={this.props.match.path == '/report-management' ? 'active' : 'null'}><i class="fas fa-list"></i> Report Management</Link></li>
                        <li><Link to={'/pages'} className={ this.props.match.path == '/pages' ? 'active' : 'null'}><i class="fas fa-list"></i> Pages</Link></li> */}
              <li>
                <Link
                  to={"/sellers"}
                  className={
                    this.props.location.pathname === "/sellers" ||
                    this.props.match.path == "/edit-sellers/:sellersId" ||
                    this.props.match.path == "/productList/:sellersId" ||
                    this.props.match.path == "/productDetails/:productId"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list" />
                  Seller's Management
                </Link>
              </li>
              <li>
                <Link
                  to={"/pages"}
                  className={
                    this.props.location.pathname === "/pages" ||
                    this.props.match.path === "/pages/:pageId"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list" />
                  Page List
                </Link>
              </li>

              <li>
                <Link
                  to={"/ad"}
                  className={
                    this.props.location.pathname === "/ad" ||
                    this.props.match.path === "/edit-ad/:adId"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list" />
                  Ad Management
                </Link>
              </li>

              <li>
                <Link
                  to={"/report-post"}
                  className={
                    this.props.location.pathname === "/report-post"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list"></i> Report Post
                </Link>
              </li>

              <li>
                <Link
                  to={"/report-user"}
                  className={
                    this.props.location.pathname === "/report-user"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list"></i> Report User
                </Link>
              </li>
              <li>
                <Link
                  to={"/payments-list"}
                  className={
                    this.props.location.pathname === "/payments-list"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list" />
                  Payment List
                </Link>
              </li>

              <li data-toggle="modal" data-target="#myModal">
                <a>
                  <i class="fas fa-list"></i> Logout
                </a>
              </li>

              <li>
                <Link
                  to={"/change-password"}
                  className={
                    this.props.location.pathname === "/change-password"
                      ? "active"
                      : "null"
                  }
                >
                  <i class="fas fa-list" />
                  Change Password
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="modal" id="myModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirm Logout</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>Are you sure you want to logout? </p>
                <div className="text-center">
                  <button
                    onClick={(event) => this.handleLogout(event)}
                    className="orange-btn mr-2"
                  >
                    Ok
                  </button>
                  <button className="orange-outline-btn">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isShowSidebar: state.commonReducer.showSidebar,
    // productApprovalCount: state.productReducer.approvalProductListCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ toggleSidebar, logoutUser }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
