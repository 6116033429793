import React from "react";
import PrivateGuard from "./privateGuard";
import { Switch, Redirect, Route, Router } from "react-router-dom";
import Sidebar from '../Containers/Sidebar/Sidebar';
import header from "../Components/Header/header"
import Login from '../Containers/Login/login'
import change_password from "../Containers/change-Password/change_password";
import forgotPassword from "../Containers/ForgotPassword/forgotPassword";
import Dashboard from "../Containers/Dashboard/dashboard";
import User_Detail from "../Containers/User-Detail/User_Detail"
import User_List from "../Containers/User_List/userList";
import Header from "../Components/Header/header";
import petofDate from "../Containers/Petofdate/petofDate";
import FaqList from "../Containers/FaqList/FaqList";
import Edit_Faq from "../Containers/Faq/FaqEdit";
import SellersList from "../Containers/Sellers/Sellers";
import Edit_Sellers from "../Containers/Edit-Sellers/SellersEdit"
import ProductsList from "../Containers/Product_list/productList"
import Products from "../Containers/Products/Products";
import reportPost from "../Containers/report-Post/reportPost";
import Payment_List from "../Containers/Payment-List/Payment_List";

import adList from "../Containers/Adcenter/AdCenter"
import AdDetails from "../Containers/AdDetails/AdDetails";
import reportUser from "../Containers/report-Post/reportUser";
import Page_Detail from "../Containers/Page-Detail/Page_Detail"
import Page_List from "../Containers/Page-List/Page_List"

class PrivateRoutes extends React.Component {
  render() {
    return (
      <div>
        <div className="PrivateArea__content">
          <div className="main_sec">
            {/* admin routes */}
            <Switch>

              <Route
                path="/"
                component={() => <Redirect to="/login" />}
                exact
              />

              <PrivateGuard
                path='/login'
                component={Login}
                exact
              />

              <PrivateGuard
                path='/forgot-password'
                component={forgotPassword}
                exact
              /> 
             <PrivateGuard
              path={'/report-post'}
              component={Container}
              />
               <PrivateGuard
              path={'/report-user'}
              component={Container}
              />
             <PrivateGuard
              path={'/change-password'}
              component={Container}
              exact
            />
           <PrivateGuard
              path={'/petDay'}
              component={Container}
              exact
            />
            <PrivateGuard
              path={'/view-post/:id'}
              component={Container}
              exact
              />
            <PrivateGuard
                path='/dashboard'
                component={Container}
                exact
              />
              <PrivateGuard
                path='/change-password'
                component={Container}
                exact
              />
              <PrivateGuard
              path={'/create-faq'}
              component={Container}
              exact
            />
            <PrivateGuard
              path={'/edit-faq/:faqId'}
              component={Container}
              exact
            />
               <PrivateGuard
                path='/faqs'
                component={Container}
                exact
              />
                <PrivateGuard
                path='/users/:userId'
                component={Container}
                exact
              />
              <Route
                path='/users'
                component={Container}
                exact
              />
              <Route
                path='/sellers'
                component={Container}
                exact
              />
               <Route
                path='/ad'
                component={Container}
                exact
              />
              <Route path="/payments-list" component={Container} exact />
                <Route
                path='/productList/:sellersId'
                component={Container}
                exact
              />
                <Route
                path='/productDetails/:productId'
                component={Container}
                exact
              />
               <Route
              path={'/edit-sellers/:sellersId'}
              component={Container}
              exact
            />
            <Route
              path={'/edit-ad/:adId'}
              component={Container}
              exact
            />
              <Route
              path={'/pages'}
              component={Container}
              exact
            />
              <Route
              path={'/pages/:pageId'}
              component={Container}
              exact
            />
              
              {/* wildcard route */}
              <Route
                path="*"
                component={() => <Redirect to="/login" />}
                exact
              />
            
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const Container = ({ match }) => {
  // console.log("match is", { match })
  return (
    <div class="main-body">
      <Sidebar />
      <div class="main-content">
        <div class="container-fluid">
          <Header />
          <Switch>

            <Route
              path={'/dashboard'}
              component={Dashboard}
              exact
            />
            <Route
              path={'/petDay'}
              component={petofDate}
              exact
            />
            <Route
              path={'/payments-list'}
              component={Payment_List}
              exact
            />
            <Route
              path={'/users'}
              component={User_List}
              exact
            />

            <Route
              path={'/users/:userId'}
              component={User_Detail}
              exact
            />
           

            <Route
              path={'/change-password'}
              component={change_password}
              exact
            />
            <Route
              path={'/faqs'}
              component={FaqList}
              exact
            />
            <Route
              path={'/sellers'}
              component={SellersList}
              exact
            />
             <Route
              path={'/ad'}
              component={adList}
              exact
            />
              <Route
              path={'/productList/:sellersId'}
              component={ProductsList}
              exact
            />
               <Route
              path={'/productDetails/:productId'}
              component={Products}
              exact
            />
                 <Route
              path={'/edit-sellers/:sellersId'}
              component={Edit_Sellers}
              exact
            />
            <Route
              path={'/edit-ad/:adId'}
              component={AdDetails}
              exact
            />
               <Route
              path={'/create-faq'}
              component={Edit_Faq}
              exact
            />
            <Route
              path={'/edit-faq/:faqId'}
              component={Edit_Faq}
              exact
            />
            <Route
              path={'/report-post'}
              component={reportPost}
              />
              <Route
              path={'/report-user'}
              component={reportUser}
              />
            <Route
              path={'/pages'}
              component={Page_List}
              exact
            />
            <Route
              path={'/pages/:pageId'}
              component={Page_Detail}
              exact
            />
          </Switch>
          <div class="main-footer">
            <p>© {new Date().getFullYear()} PetLoverschat. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  )
};




export default PrivateRoutes;
