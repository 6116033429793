import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getPaymentsList = (offset) => async (dispatch) => {
    // console.log("getOrdersList userId is", userId, " offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/payments/list?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}

export const searchPayment = (offset, searchKeyword) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/admin/payments/by/search?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        // console.log("orders list response from backend", res);
        return res;
    }
    catch (error) {
        console.log('wrong', error);
        dispatch(handleError(error));
    }
}


export const getPaymentsListForVendor = (offset, userId) => async (dispatch) => {
    // console.log("getOrdersList userId is", userId, " offset is", offset);
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/${userId}/paymentList?offset=${offset}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
  
      return res;
    } catch (error) {
      console.log("wrong", error);
      dispatch(handleError(error));
    }
  };
  
  export const searchPaymentForVendor = (
    offset,
    searchKeyword,
    userId,
    paymentStatus,
    orderStatus
  ) => async (dispatch) => {
    // console.log("getOrdersList offset is", offset);
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/${userId}/payments/by/search?offset=${offset}&search=${searchKeyword}&paymentStatus=${paymentStatus}&orderStatus=${orderStatus}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // console.log("orders list response from backend", res);
      return res;
    } catch (error) {
      console.log("wrong", error);
      dispatch(handleError(error));
    }
  };
  