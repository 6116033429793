import React, { Component } from 'react';
// import logo from '../../Assets/images-2/logo.png';
// import '../../Styles/sidebar.scss'
import '../../Styles/dashboard.scss'
import { Link } from 'react-router-dom';

// import Chart from "react-apexcharts";

// import ratioGraph from '../../Assets/images-2/ratio-graph.jpg';
// import adminDashGraph from '../../Assets/images-2/admin-dash-graph.jpg';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutUser } from '../../Redux/Actions/authAction';
import { toggleSidebar } from '../../Redux/Actions/commonAction';
import { getUsersList } from '../../Redux/Actions/userAction';
import { getStatisticsData } from '../../Redux/Actions/dashboardAction';
import { debounce } from 'lodash';

class Dashboard extends Component {

    monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 5,
            users: [],
            filter: "monthly",
            startDate: "",
            endDate: "",
            totalPayments: 0,
            totalReceivableAmount: 0,
            totalOrders: 0,
            series: [],
            options: {}
        };
    }

    componentDidMount() {
        this.debounceStatisticsCall();
        this.fetchMoreData();
    }

    handleFilterChange = (filterName) => {
        this.setState({
            filter: filterName
        });

        this.debounceStatisticsCall();
    }


    handleDateChange = (event, key) => {
        event.preventDefault();
        this.setState({
            [key]: event.target.value,
            filter: 'date'
        });

        this.debounceStatisticsCall();
    }


    debounceStatisticsCall = debounce(() => {
        this.fetchStatisticsData();
    }, 1000);


    fetchStatisticsData = async () => {

        let result;

        if (this.state.filter === "date") {
            result = await this.props.getStatisticsData(this.state.filter, this.state.startDate, this.state.endDate);
        } else {
            result = await this.props.getStatisticsData(this.state.filter);
        }

        if (result ?.data ?.isFound && result.data.data) {
            this.createSeriesData(result.data.data)
            // this.setState({
            //     totalPayments: result.data.data.payments,
            //     totalOrders: result.data.data.orders
            // })
        }
    }


    createSeriesData = (statsData) => {
        let paymentData = statsData.payments;
        let orderData = statsData.orders;
        let totalPayments = 0;
        let totalReceivableAmount = 0;
        let totalOrders = 0;
        let maxPaymentValue = 0;
console.log("paymentData",statsData,"DD",statsData.payments)
        let seriesData = paymentData.length > 0 ? paymentData.map((item) => {
            totalPayments += item.payments;

            totalReceivableAmount += item.payments

            let parsedDate = new Date(item.createTS);

            if (this.state.filter === 'weekly' || this.state.filter === 'monthly' || this.state.filter === 'date') {
                let todate = new Date(parsedDate).getDate();
                let tomonth = this.monthArray[new Date(parsedDate).getMonth()];
                let toyear = new Date(parsedDate).getFullYear();
                parsedDate = `${todate} ${tomonth}`
            }
            else if (this.state.filter === 'annually') {
                parsedDate = this.monthArray[new Date(parsedDate).getMonth()];
            }
            else if (this.state.filter === 'all') {
                parsedDate = new Date(parsedDate).getFullYear();
            }

            maxPaymentValue = item.payments > maxPaymentValue ? item.payments : maxPaymentValue

            if ((typeof parsedDate == 'number')) {
                parsedDate = parsedDate.toString();
            }

            return {
                x: parsedDate,
                y: item.payments.toFixed(2)
            }

        }) : [];

        orderData.forEach(item => {
            totalOrders += item.orders
        });

        maxPaymentValue = Math.ceil(maxPaymentValue / 100) * 100;

        // console.log("seriesData is", seriesData);


        this.setState({
            totalPayments,
            totalReceivableAmount: totalReceivableAmount,
            totalOrders,
            series: [{
                name: "Total Payments",
                data: seriesData
            }],
            options: {
                chart: {
                    height: 350,
                    type: "line",
                    toolbar: {
                        show: true,
                        tools: {
                            download: false,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true | '<img src="/static/icons/reset.png" width="20">'
                        },
                    }
                },
                fill: {
                    colors: ['#F44336']
                },
                colors: ["#E25E52"],
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: "smooth"
                },
                title: {
                    text: "Sales Overview",
                    align: "left"
                },
                grid: {
                    borderColor: "#e7e7e7",
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return "$" + value;
                        }
                    },
                    title: {
                        text: "Amount"
                    },
                    min: 0,
                    max: maxPaymentValue
                },
                legend: {
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            }
        })
    }


    fetchMoreData = async () => {
        const result = await this.props.getUsersList(this.state.offset, this.state.limit);
        if (result ?.data ?.data ?.length > 0) {
            this.setState({
                users: result.data.data
            })
        }
    };


    handleLogout = async (event) => {
        event.preventDefault();
        this.props.logoutUser();
        this.props.history.push("/login");
    };




    render() {

        const usersList = this.state.users.map(i => {
            const date = new Date(i.createts)
            // const fullAddress = i.address ? JSON.parse(i.address) : null;
            const createts = i.createts ? (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear()) : null;
            return (
                <tr key={i.id}>
                    {/* <td>
                        <div class="vendor-table-check">
                            <label class="custon-radio">
                                <input type="checkbox" name="starter" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td> */}
                    <td key={i.id} align="center">{i.id ? i.id : "-"}</td>
                    <td>{i.first_name ? i.first_name + " " + i.last_name : "-"}</td>
                    <td>{i.email ? i.email : "-"}</td>
                    <td>{createts}</td>
                    <td>{i.isactive ? "Yes" : "No"}</td>
                    <td align="center">
                        <Link to={`users/${i.id}`} >view detail</Link>
                    </td>
                </tr>
            )
        });

        return (
            <div class="admin-card">

                <div class="admin-card-head">
                    <h2>dashboard</h2>
                    {/* <div class="admin-card-head-right">
                        <div class="dash-time">
                            <ul>
                                <li><a onClick={() => this.handleFilterChange("all")} className={this.state.filter === "all" ? "active" : null} href="javascript:;">all</a></li>
                                <li><a onClick={() => this.handleFilterChange("weekly")} className={this.state.filter === "weekly" ? "active" : null} href="javascript:;">weekly</a></li>
                                <li><a onClick={() => this.handleFilterChange("monthly")} className={this.state.filter === "monthly" ? "active" : null} href="javascript:;">monthly</a></li>
                                <li><a onClick={() => this.handleFilterChange("annually")} className={this.state.filter === "annually" ? "active" : null} href="javascript:;">annually</a></li>
                            </ul>
                        </div>
                        <div class="dash-date">
                           
                            <p>Start Date:</p>
                            <input value={this.state.startDate} onChange={event => this.handleDateChange(event, "startDate")} max={this.state.endDate} type="date" class="form-control" />

                            <p>End Date:</p>
                            <input value={this.state.endDate} onChange={event => this.handleDateChange(event, "endDate")} min={this.state.startDate} type="date" class="form-control" />
                            
                        </div>
                    </div> */}
                </div>

                {/* <div class="admin-dash-ratio">
                    <div class="row">

                        <div class="col-lg-6 col-md-8">
                            <div class="admin-dash-ratiobox">
                                <div class="admin-dash-ratiobox-top">
                                    <div class="admin-dash-ratiobox-icon">
                                        <i class="fas fa-wallet"></i>
                                    </div>
                                    <div class="admin-dash-ratiobox-detail">
                                        <p>Total Sale</p>
                                        <h3>${this.state.totalPayments.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                                    </div>
                                </div>
                                <div class="admin-dash-ratiobox-bottom">
                                    <div class="admin-dash-ratiobox-graph">                                     
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="col-lg-4 col-md-6">
                            <div class="admin-dash-ratiobox">
                                <div class="admin-dash-ratiobox-top">
                                    <div class="admin-dash-ratiobox-icon">
                                        <i class="fas fa-cart-plus"></i>
                                    </div>
                                    <diov class="admin-dash-ratiobox-detail">
                                        <p>Total Orders</p>                                       
                                        <h3>{this.state.totalOrders} </h3>
                                    </diov>
                                </div>
                                <div class="admin-dash-ratiobox-bottom">
                                    <div class="admin-dash-ratiobox-graph">                                       
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}

                {/* <div class="admin-dash-graph">
                    <div class="admin-dash-graph-top">
                        <div class="row">
                            <div class="col-md-6 co-lg-6">
                                <div class="admin-dash-graph-heading">
                                    <h3>Sales Overview</h3>                                    
                                </div>
                            </div>
                            <div class="col-md-6 co-lg-6">
                                <div class="admin-dash-graph-ratio">
                                    <p>Total Sale</p>
                                    <h3>${this.state.totalPayments.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="admin-dash-graph-img">                        
                    </div>
                </div> */}


                <div class="admin-card-box">
                    <div class="vendor-table">

                        <div class="vendor-table-head">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="entries-drop">
                                        <label>USERS LIST</label>
                                        {/* <select class="entries-controls">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <p>Entries</p> */}
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="vendor-table-head-right">
                                        {/* <div class="vendor-table-search mr-2">
                                            <input class="form-control" type="text" placeholder="search" />
                                        </div> */}
                                        <div class="download-statement-btn">
                                            <Link to={'/users'}>
                                                <button type="button" class="hyper-link-btn">see all</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="vendor-table-body">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            {/* <td>
                                                <div class="vendor-table-check">
                                                    <label class="custon-radio">
                                                        <input type="checkbox" name="starter" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </td> */}
                                            <td align="center">User ID.</td>
                                            <td>Full Name</td>
                                            <td>Email</td>
                                            <td>Created</td>
                                            <td>Active</td>
                                            <td align="center">View Detail</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {usersList}
                                    </tbody>

                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>                           
    
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ logoutUser, toggleSidebar, getUsersList, getStatisticsData }, dispatch)
}

export default connect(null, mapDispatchToProps)(Dashboard)
