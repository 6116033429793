import { Component } from "react";
import "../../Styles/order-detail.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getpageDetail,
  updatePageDetail,
} from "../../Redux/Actions/pageAction";


import RichTextEditor from "react-rte";

class Page_Detail extends Component {
  generatedContentTemplate = "";


  onChange = (value) => {
    this.setState({ value });
    console.log(value.toString("html"));
    this.setState((prevState) => {
      return (prevState.pageDetail["content"] = value.toString("html"));
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      pageDetail: {},
      isEditForm: false,
      errorData: {},
      value: RichTextEditor.createEmptyValue(),
      editorType: "text",
    };

    this.modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    };

    this.formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      // 'link', 'image', 'video'
    ];

    this.rteChange = this.rteChange.bind(this);
  }
  
  componentDidMount() {
    this.fetchPageDetail();
  }
  
  fetchPageDetail = async () => {
    console.log("fetch data")
    const result = await this.props.getpageDetail(
      this.props.match.params.pageId
      );

      console.log(this.props.match.params.pageId)
      if (result?.data?.data) {
        await this.setState({
          pageDetail: result.data.data,
          value: RichTextEditor.createValueFromString(
            result.data.data.content,
            "html"
            ),
          });
    }
  };

  onEdit = () => {
    this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
    
    this.setState({
      isEditForm: true,
    });
  };

  rteChange = (content, delta, source, editor) => {


    this.setState((prevState) => {
      return (prevState.pageDetail["content"] = editor.getHTML());
    });
  };

  handleChange = async (event, field) => {
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    console.log("event is", event);
    event.persist();
    this.setState((prevState) => {
      return (prevState.pageDetail[field] = event.target.value);
    });
    if (field == "content") {
      console.log(event.target.value, "event.target.value");

      await this.setState({
        value: RichTextEditor.createValueFromString(event.target.value, "html"),
      });
    }
  };

  validateInfo = () => {
    let isFormValid = true;
    this.state.errorData = {
      title: [],
      content: [],
    };

    if (this.state.pageDetail.title.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.title = ["Title cannot be empty."];
    }

    if (this.state.pageDetail.content.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.content = ["Content cannot be empty."];
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  onSaveForm = async () => {
    if (this.validateInfo()) {
      this.setState({
        isEditForm: false,
      });
      const res = await this.props.updatePageDetail(this.state.pageDetail);
      if (res && res.data && !res.data.isError) {
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        this.setState({
          errorData: {},
        });
      } else {
        this.setState({
          ...this.statePrevCopy,
          errorData: {},
        });
      }
    }
  };

  onCancel = async () => {
    console.log("state copy is", this.statePrevCopy);

    await this.setState({
      ...this.statePrevCopy,
    });

    await this.setState({
      value: RichTextEditor.createValueFromString(
        this.statePrevCopy.pageDetail.content,
        "html"
      ),
    });
  };

  render() {
    return (
      <div class="admin-card">
        <div class="order-heading">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2 class="mb-0">page details</h2>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="order-edit">
                {(() => {
                  if (!this.state.isEditForm) {
                    return (
                      <button
                        onClick={this.onEdit}
                        type="text"
                        class="orange-outline-btn"
                      >
                        <i class="far fa-edit"></i> Update
                      </button>
                    );
                  } else {
                    return (
                      <>
                        <button
                          onClick={this.onSaveForm}
                          type="text"
                          class="orange-outline-btn"
                        >
                          <i class="fa fa-check"></i> Save
                        </button>
                        <button
                          onClick={this.onCancel}
                          type="text"
                          class="orange-outline-btn"
                        >
                          <i class="fa fa-times"></i> Cancel
                        </button>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
        <div class="admin-card-box">
          <section class="track-section">
            <div class="container">
              <form>
                <div class="form-group">
                  <label for="pageTitleInput">Page Title</label>
                  {(() => {
                    if (!this.state.isEditForm) {
                      return (
                        <p>
                          {this.state.pageDetail.title
                            ? this.state.pageDetail.title
                            : `-`}
                        </p>
                      );
                    } else {
                      return (
                        <>
                          <input
                            type="text"
                            onChange={(event) =>
                              this.handleChange(event, "title")
                            }
                            value={this.state.pageDetail.title}
                            class="form-control"
                            id="pageTitleInput"
                            placeholder="Add page title"
                          />
                          <span
                            style={{ color: "red", fontSize: "12px" }}
                            className="form-field-error"
                          >
                            {this.state.errorData?.title &&
                              this.state.errorData.title[0]}
                          </span>
                        </>
                      );
                    }
                  })()}
                </div>
                <div class="form-group">
                  <label for="pageContentTextarea">Page Content</label>

                  {(() => {
                    if (!this.state.isEditForm) {
                      return (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.pageDetail.content
                              ? this.state.pageDetail.content
                              : `-`,
                          }}
                        ></p>
                      );
                    } else {
                      return (
                        <>
                          <div className="editor_switch mb-4">
                            {(() => {
                              return (
                                <>
                                  <button
                                    className={
                                      this.state.editorType == "html"
                                        ? "low-opacity-button orange-outline-btn"
                                        : "orange-btn mr-3"
                                    }
                                    onClick={() => {
                                      this.setState({ editorType: "text" });
                                    }}
                                    type="button"
                                  >
                                    Visual
                                  </button>
                                  <button
                                    className={
                                      this.state.editorType == "text"
                                        ? "low-opacity-button orange-outline-btn"
                                        : "orange-btn ml-3"
                                    }
                                    onClick={() => {
                                      this.setState({ editorType: "html" });
                                    }}
                                    type="button"
                                  >
                                    HTML
                                  </button>
                                </>
                              );
                            })()}
                          </div>
                        
                          {(() => {
                            if (this.state.editorType == "html")
                              return (
                                <textarea
                                className="textarea-custom"
                                
                                  onChange={(event) =>
                                    this.handleChange(event, "content")
                                  }
                                  value={this.state.value.toString("html")}
                                ></textarea>
                              );
                            else
                              return (
                                <RichTextEditor
                                  value={this.state.value}
                                  onChange={this.onChange}
                                />
                              );
                          })()}

                         
                          <span
                            style={{ color: "red", fontSize: "12px" }}
                            className="form-field-error"
                          >
                            {this.state.errorData?.content &&
                              this.state.errorData.content[0]}
                          </span>
                        </>
                      );
                    }
                  })()}
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getpageDetail, updatePageDetail }, dispatch);
};

export default connect(null, mapDispatchToProps)(Page_Detail);
