import React, { Component, useState } from "react";
import "../../Styles/user-detail.scss";
import profileAvtar from "../../Assets/images-2/dummyUser.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetail, updateProfile } from "../../Redux/Actions/userAction";
import { getPetDate,addpetofday } from "../../Redux/Actions/petofDayAction";

import Calendar from 'react-calendar';
import moment from 'moment'
import 'react-calendar/dist/Calendar.css';




class User_Detail extends Component {

  fieldsList = {
    address: "",
    addressId: null,
    city: "",
    country: "",
    createts: "",
    email: "",
    first_name: "",
    id: 0,
    imagename: "",
    isacceptedtnc: 0,
    isactive: 0,
    last_name: "",
    phone_number: "",
    role: "",
    state: "",
    type: "",
    updatedTS: "",
    updatedts: "",
    userId: 0,
    zipCode: "",
    successMessage: "",
    errorMessage: "",
    errorData: {},
    selectedImg: null,
    petdate: [],
    date: "",
    profile_image:"",
    disable:false,
    authData: {
      name: "",
      image: "",
    },
  };
  timestamp = "?time=" + new Date().getTime();
  constructor(props) {
    super(props);
    this.state = { ...this.fieldsList };
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    
    this.fileUpload = React.createRef();
  }

  async fileSelectHandler(e) {
    let imgStatus = false;
    if (e.target.files[0] !== undefined)
      if (e.target.files[0].type !== undefined) {
        let imgType = e.target.files[0].type.split("/");
        let MimeTypeArray = ["jpg", "jpeg", "png", "gif"];

        if (imgType[1] !== undefined) {
          if (MimeTypeArray.includes(imgType[1])) {
            if (e.target && e.target.files[0]) {
              let img = e.target.files[0];
              this.setState({
                selectedImg: img,
              });
              this.readFile(img);
            }
          } else {
            imgStatus = true;
          }
        }
      }
    this.setState({ invalidImage: imgStatus });
  }
  fetchUser = async (userId) => {
    let result = await this.props.getUserDetail(userId);
    console.log("details",result);


    if (result && result.data && !result.isError) {
      if (result.data.data.address === null) {
        result.data.data.address = "";
      }
      if (result.data.data.phonenumber === null) {
        result.data.data.phonenumber = "";
      }
      this.setUserState(result.data.data);
    } else {
      console.log("something went wrong while getting user details");
    }
  };
  fecthDates = async () => {
    let results = await this.props.getPetDate();

    this.setState({
      ...this.state.petdate, petdate: results.data.map((item, index) => (

        moment(item.date).format("MM-DD-YYYY")
      ))
    })




  }


  setUserState(userInfo) {
    this.setState({
      ...userInfo,
    });
  }


  componentDidMount() {
    this.fetchUser(this.props.match.params.userId);
    this.fecthDates();
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.match.params.userId !== this.props.match.params.userId) {
      this.getProductDetail(nextProps.match.params.productid);
    }
  }

  readFile(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState({
        authData: {
          ...this.state.authData,
          image: [fileReader.result],
        },
      });
    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  clearErrors = (name) => {
    if (this.state.errorData.hasOwnProperty(name) && this.state.errorData[name].length !== 0) {
      this.state.errorData[name] = "";
    }
  };

  handleChange = (e, key) => {
    console.log("datessssssssssssss", moment(e).format("DD-MM-YYYY"));
    const { name, value } = e.target;
    this.clearErrors(key);
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handledateChange = (e, key) => {
     
    const value = moment(e).format("yyyy-MM-DD")
    this.setState((prevState) => ({
      
      date: value,
    }))
    console.log("datessss",this.state.date);
  };



validateUserInfo = () => {
  let isFormValid = true;
  this.state.errorData = {
    firstname: [],
    lastname: [],
    phonenumber: [],
    address: []
  };

  if (this.state.first_name.trim().length === 0) {
    isFormValid = false;
    this.state.errorData.firstname = ["First Name cannot be empty!"];
  }
  if (this.state.last_name.trim().length === 0) {
    isFormValid = false;
    this.state.errorData.lastname = ["Last Name cannot be empty!"];
  }

  // if (this.state.phonenumber.trim().length === 0) {
  //   isFormValid = false;
  //   this.state.errorData.phonenumber = ["Phone Number cannot be empty!"];
  // } else if (this.state.phonenumber.trim().length < 10) {
  //   isFormValid = false;
  //   this.state.errorData.phonenumber = ["Invalid Phone Number!"];
  // }

  // if (this.state.address.trim().length === 0) {
  //   isFormValid = false;
  //   this.state.errorData.address = ["Address cannot be empty!"];
  // }

  let tempState = this.state;
  this.setState({
    ...tempState,
  });

  return isFormValid;
};




handleSubmit = async () => {
  this.setState({ disable: true});


  let st = this.state;
  this.setState((prevState) => ({
    ...st,
    disable:true,
    successMessage: "",
    errorMessage: "",
    showLoader: true,
  }));
  let valid = await this.validateUserInfo();
  if (valid) {
    console.log("dbchdb", this.state.isactive)
    const profile = this.state;
        await this.props.addpetofday({ profile });
   

      const result = await this.props.updateProfile({ profile });
  

    

    if (result.status === 201) {
   
      this.setState((prevState) => ({
        ...prevState,
        timestamp: Date.now(),
        successMessage: "Profile updated successfully!",
        showLoader: false,
        // disable:false
      }));
    } else {

      this.setState((prevState) => ({
        ...prevState,
        errorMessage: "Something went wrong! Please try after some time.",
        showLoader: false,
        disable:false
      }));
    }
  }
  else {

    // discount validations failed
    this.setState({
      showLoader: false,
      disable:false
    });
  }

};

triggerClick = () => {
  this.fileUpload.current.click();
};



render() {


  return (
    <div class="admin-card">
      <div class="order-heading">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <h2 class="mb-0">user profile</h2>
          </div>
        </div>
      </div>
      <div class="admin-card-box">
        <section className="cart-section">
          <div className="profile">
            {/* <div className="cart-section-head">
                                <h2>profile</h2>
                                <p>Setup your general profile details.</p>
                            </div> */}
            {(() => {
              if (
                this.state.successMessage !== "" &&
                this.state.successMessage !== undefined
              ) {
                return (
                  <div className="alert alert-success">
                    {this.state.successMessage}
                  </div>
                );
              } else if (
                this.state.errorMessage !== "" &&
                this.state.errorMessage !== undefined
              ) {
                return (
                  <div className="alert alert-danger">
                    {this.state.errorMessage}
                  </div>
                );
              }
            })()}
            <div className="profile-body">
              <div className="row">
                <div className="col-lg-8 col-md-7 order-md-1 order-2">
                  <div className="profile-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            maxLength="45"
                            value={this.state?.first_name}
                            onChange={(event) =>
                              this.handleChange(event, "first_name")
                            }
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.firstname &&
                              this.state.errorData.firstname[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            maxLength="45"
                            value={this.state?.last_name}
                            onChange={(event) =>
                              this.handleChange(event, "last_name")
                            }
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.lastname &&
                              this.state.errorData.lastname[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            value={this.state?.email}
                            onChange={(event) =>
                              this.handleChange(event, "email")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            maxLength="10"
                            value={this.state?.phone_number}
                            onChange={(event) =>
                              this.handleChange(event, "phone_number")
                            }
                          />
                          <span className="form-field-error">
                            {this.state.errorData?.phonenumber &&
                              this.state.errorData.phonenumber[0]}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          {/* <DatePicker
                          // selected={'17/11/2021'}
                          minDate={new Date("11-16-2021")}                          // onChange={(date) =>setSelectDate(date)}
                           

                         
                             
                           /> */}
                          {/* <input type="date" name="make pet of the day" min="2021-11-20" />
                            <span className="form-field-error">
                              {this.state.errorData?.address &&
                                this.state.errorData.address[0]}
                            </span> */}
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                          <div className="form-group">
                            <select name="country" className="form-control">
                                                                        <option value="volvo">country</option>
                                                                        <option value="saab">country1</option>
                                                                        <option value="mercedes">country2</option>
                                                                        <option value="audi">countr3</option>
                                                                        </select> 
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder="Country"
                              maxLength="50"
                              value={this.state?.country}
                              onChange={(event) =>
                                this.handleChange(event, "country")
                              }
                            />
                          </div>
                        </div> */}
                      {/* <div className="col-lg-6">
                          <div className="form-group">
                            <select name="state" className="form-control">
                                                                    <option value="volvo">state</option>
                                                                    <option value="saab">state1</option>
                                                                    <option value="mercedes">state2</option>
                                                                    <option value="audi">state3</option>
                                                                    </select>
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder="State"
                              maxLength="45"
                              value={this.state?.state}
                              onChange={(event) =>
                                this.handleChange(event, "state")
                              }
                            />
                          </div>
                        </div> */}
                      {/* <div className="col-lg-6">
                          <div className="form-group">
                            <select name="country" className="form-control">
                                                                    <option value="volvo">city</option>
                                                                    <option value="saab">city1</option>
                                                                    <option value="mercedes">city2</option>
                                                                    <option value="audi">city3</option>
                                                                    </select>
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder="City"
                              maxLength="45"
                              value={this.state?.city}
                              onChange={(event) =>
                                this.handleChange(event, "city")
                              }
                            />
                          </div>
                        </div> */}
                      {/* <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              maxlength="6"
                              pattern="[0-9]"
                              placeholder="Zip Code"
                              value={this.state?.zipCode}
                              onChange={(event) =>
                                this.handleChange(event, "zipCode")
                              }
                            />
                          </div>
                        </div> */}

                      
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Make Pet of the Day
                          </label>
                          <Calendar
                            
                            style={{ height: 500 }}
                            onChange={(event) =>
                              this.handledateChange(event, "date")
                            }
                            // tileDisabled={this.tileDisabled}
                            // value={moment(this.state?.date).format("DD-MM-YYYY")}
                            tileClassName={({ date, view }) => {
                              if (this.state.petdate.find(x => x === moment(date).format("MM-DD-YYYY"))) {
                                return 'highlight'
                              }
                            }}



                            minDate={
                              new Date()
                            }
                          >
                          </Calendar>
                        </div>
                      </div>
                      {console.log("active",this.state.isactive)}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Is Active?</label>
                          <div className="custom-radio-input">
                            <p className="container-sub mr-2">
                              Inactive
                              <span>
                                <input
                                  type="radio"
                                  value="0"
                                  onChange={(event) =>
                                    this.handleChange(event, "isactive")
                                  }
                                  checked={this.state.isactive == 0}
                                  name="radio"
                                />
                              </span>
                              <span class="checkmark"></span>
                            </p>

                            <p className="container-sub mr-2">
                              Active
                              <span>
                                <input
                                  type="radio"
                                  value="1"
                                  onChange={(event) =>
                                    this.handleChange(event, "isactive")
                                  }
                                  checked={this.state.isactive == 1}
                                  name="radio"
                                />
                              </span>
                              <span class="checkmark"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-btn">
                      <button disabled={this.state.disable} type="button" onClick={this.handleSubmit}>
                        continue
                        {(() => {
                          if (this.state?.showLoader) {
                            return (
                              <span class="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-5 order-md-2 order-1">
                  <div className="profile-picture text-center">
                    <div className="profile-picture-head">
                      <h3>Profile Picture</h3>
                    </div>
                    <div className="user-profile-img">
                      {/* {(() => {
                                                                if (this.state.authData.image)
                                                                    return <img src={this.state.authData.image} />
                                                                else if (this.state.imagename && this.state.imagename !== "undefined")
                                                                    return <img src={this.state.imagename} />
                                                                else
                                                                    return <img src={profileAvtar} />
                                                             })()} */}
                                                              {console.log("image",this.state.profile_image)}
                      <img
                        src={
                          this.state.profile_image?this.state.profile_image
                            :
                          profileAvtar
                        }
                      />
                    </div>
                    {(() => {
                      if (
                        this.state?.invalidImage &&
                        this.state.invalidImage
                      ) {
                        return (
                          <span className="form-field-error">
                            Invalid image format
                          </span>
                        );
                      }
                    })()}

                    {/* <div className="upload-btn">
                        <button onClick={this.triggerClick} type="button">
                          upload image
                        </button>
                      </div> */}
                    {/* <input
                        ref={this.fileUpload}
                        className="droup-file-input"
                        type="file"
                        onChange={this.fileSelectHandler}
                        style={{ display: "none" }}
                      ></input> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserDetail, updateProfile, getPetDate,addpetofday}, dispatch);
};

export default connect(null, mapDispatchToProps)(User_Detail);
