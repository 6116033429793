import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createFaq, getFaqDetail, updateFaqDetail, deleteFaqDetail } from '../../Redux/Actions/faqsAction';

class Edit_Faq extends Component {

    constructor(props) {
        super(props);

        this.state = {
            faqId: "",
            faqName: "",
            parentId: "0",
            faqDescription: "",
            isEdit: false,
            successMessage: '',
            errorMessage: '',
            errorData: {},
            showLoader: false,            
            faqDescription: "",
            showSubmitButton:false
        }


        if (this.props.match.path === "/edit-faq/:faqId") {
            console.log('editcheck......');
            // this.props.match.params.couponId;
            this.fetchfaqDetail(this.props.match.params.faqId);
        }

    }

    fetchfaqDetail = async (faqId) => {        
        let faqDetail = await this.props.getFaqDetail(faqId);

        if (faqDetail ?.data ?.data ?.length > 0) {
            faqDetail = faqDetail.data.data[0];

            this.setState({
                faqId: faqDetail.id,
                faqName: faqDetail.name,
                faqDescription: faqDetail.description,
                isEdit: true,
            })
        }
        else {
            // faq not found
            // redirect user to product faq page
            this.props.history.push("/faqs");
        }
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }


    validatefaqInfo = () => {
        let isFaqValid = true;
        this.state.errorData = {
            faqName: [],
            faqDescription: [],
            parentId: [],
        }

        if (this.state.faqName.trim().length === 0) {
            isFaqValid = false;
            this.state.errorData.faqName = ["Name field cannot be empty."]
        }

        if (this.state.faqDescription.trim().length === 0) {
            isFaqValid = false;
            this.state.errorData.faqDescription = ["Faq description field cannot be empty."]
        }

        

        if (this.state.parentId.trim().length === 0) {
            isFaqValid = false;
            this.state.errorData.parentId = ["faq Parent Id field cannot be empty."]
        }

        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFaqValid;

    }


    handleSubmit = async () => {
        await this.setState({
            successMessage: '',
            errorMessage: '',
            showLoader: true,
            showSubmitButton:true
        });

        if (this.validatefaqInfo()) {
            // faq validations passed
            let res;
            const { faqId, faqName, parentId, faqDescription } = this.state;

            if (this.state.isEdit) {
                // call update action
                res = await this.props.updateFaqDetail(
                    { faqName, parentId,  faqId, faqDescription }, faqId
                )
            }
            else {
                // call create action
                res = await this.props.createFaq(
                    { faqName, parentId, faqDescription }
                )
            }

            if (res && res.data) {
                if (res.data.isSuccess) {
                    // success case
                    this.setState({
                        successMessage: res.data.message,
                        showLoader: false
                    })

                    window.scrollTo(0, 0);

                    if (this.state.isEdit) {
                        setTimeout(() => {
                            this.setState({
                                successMessage: ''
                            })
                        }, 4000);
                    }
                    else {
                        this.setState({
                            faqId: "",
                            faqName: "",
                            faqDescription:"",
                            parentId: "0"
                        })

                        setTimeout(() => {
                            this.setState({
                                successMessage: ''
                            })
                        }, 4000);
                    }

                }
                else {
                    // response with failed case
                    this.setState({
                        errorMessage: res.data.message,
                        showLoader: false
                    })
                }
            }
            else {
                // error to get respone
                this.setState({
                    errorMessage: "Something went wrong. Please try again later.",
                    showLoader: false
                })
            }
        }
        else {
            // discount validations failed
            this.setState({
                showLoader: false
            });
        }
        this.setState({showSubmitButton:false});   
    }

    render() {
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>{this.props.match.params.faqId ? "Edit" : "Create"} Faq</h2>
                </div>
                <div class="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Name
                                    <sup>*</sup>
                                </label>
                                <input value={this.state.faqName} onChange={(e) => this.handleChange(e, "faqName")} type="text" class="form-control" />
                                <span className="faq-field-error">
                                    {this.state.errorData ?.faqName &&
                                        this.state.errorData.faqName[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Description
                                    <sup>*</sup>
                                </label>
                                
                               <textarea  value={this.state.faqDescription} onChange={(e) => this.handleChange(e, "faqDescription")} type="text" class="form-control">{this.state.faqDescription}</textarea>
                                <span className="faq-field-error">
                                    {this.state.errorData ?.faqDescription &&
                                        this.state.errorData.faqDescription[0]}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="faq-btn text-center">
                                <button type="button"  disabled={this.state.showSubmitButton}  onClick={this.handleSubmit} class="orange-outline-btn">submit
                                {(() => {
                                        if (this.state ?.showLoader) {
                                            return <span class="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ createFaq, getFaqDetail, updateFaqDetail, deleteFaqDetail }, dispatch)
}

export default connect(null, mapDispatchToProps)(Edit_Faq)