import React, { Component } from 'react'
import '../../Styles/order-list.scss'
import Header from '../../Components/Header/header';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUsersList, searchUser } from '../../Redux/Actions/userAction';
import { debounce } from 'lodash';
import defaultUserprofile from "../../Assets/Images/defaultUserprofile.png";

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};
console.log("jgjffjenfdk")
class User_List extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            maxLimit: 10,
            offset: 0,
            prevOffset: 0,
            users: [],
            searchKeyword: "",
            hasMore: true,
            showLoader: false
        };
    }

    componentDidMount() {
        this.fetchMoreData(true);
    }


    fetchMoreData = async (firstLoad) => {
        this.setState({
            showLoader: true
        })
        if (this.state.offset !== this.state.prevOffset || firstLoad) {
            if (this.state.hasMore && this.state.maxLimit && (this.state.maxLimit === "all" || this.state.maxLimit > this.state.offset)) {
                let result;
                if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
                    result = await this.props.searchUser(this.state.offset, this.state.searchKeyword.trim());
                }
                else {
                    result = await this.props.getUsersList(this.state.offset);
                    console.log("dvgvdfc",result);
                }

                // let result = await this.props.getUsersList(this.state.offset);
                if (result ?.data ?.data ?.length > 0) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            prevOffset: prevState.offset,
                            offset: prevState.offset + 10,
                            users: prevState.users.concat(result.data.data),
                            showLoader: false
                        }
                    })
                }
                else {
                    this.setState({ hasMore: false, showLoader: false });
                }
            }else {
                this.setState({
                    showLoader: false
                })
            }
        }else {
            this.setState({
                showLoader: false
            })
        }
    };

    handleChange = async (event, field) => {
        event.preventDefault();
        // console.log("event value is", +event.target.value);

        if (field === "maxLimit") {
            await this.setState({
                [field]: event.target.value === "all" ? event.target.value : +event.target.value
            })
            this.fetchMoreData(false);
        }
        else {
            await this.setState({
                [field]: event.target.value,
                offset: 0,
                prevOffset: 0,                
                hasMore: true
            })

            this.debounceSearchCall();
        }
    };

    debounceSearchCall = debounce(() => {
        this.setState({
            users: []
        })
        this.fetchMoreData(true);
    }, 1000);   

    render() {       
        const usersList = this.state.users.map(i => {
            const date = new Date(i.createts)
            // const fullAddress = i.address ? JSON.parse(i.address) : null;
            const createts = i.createts ? (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear())  : null;
            return (
                <tr key={i.id}>
                    {/* <td>
                        <div class="vendor-table-check">
                            <label class="custon-radio">
                                <input type="checkbox" name="starter" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </td> */}
                    <td  className="d-flex align-items-center">
            <div className="user-pet-pic">
              <img alt="-" src={i?.profile_image ? i.profile_image : defaultUserprofile} />
            </div>
            {i.first_name ? i.first_name + " " + i.last_name : "-"}
          </td>
                    {/* <td>{i.lastname ? i.lastname : "-"}</td> */}
                    <td>{i.email ? i.email : "-"}</td>
                    <td>{createts}</td>
                    <td>{i.isactive ? "Yes" : "No"}</td>
                    <td align="center">
                        <Link to={`users/${i.id}`} >view detail</Link>
                    </td>
                    {/* <td>{i.totalSubscriptions}</td>
                    <td align="center">
                        <Link to={`subscription/${i.id}`} >Subscription detail</Link>
                    </td> */}
                </tr>
            )
        })
        return (
            <div class="admin-card">
                <div class="admin-card-head">
                    <h2>Users List</h2>
                </div>
                <div class="admin-card-box">
                    <div class="vendor-table">

                        <div class="vendor-table-head">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="entries-drop">
                                        <label>show</label>
                                        <select class="entries-controls" onChange={(e) => this.handleChange(e, "maxLimit")} value={this.state.maxLimit}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="all">All</option>
                                        </select>
                                        <p>Entries</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="vendor-table-head-right">
                                        <div class="vendor-table-search mr-2">
                                            <input class="form-control" onChange={(e) => this.handleChange(e, "searchKeyword")} value={this.state.searchKeyword} type="text" placeholder="search" />
                                        </div>
                                        {/* <div class="download-statement-btn">
                                            <button type="button" class="hyper-link-btn">download statement</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="vendor-table-body">
                            <div class="table-responsive">
                                <InfiniteScroll
                                    dataLength={this.state.users.length}
                                    next={() => this.fetchMoreData(false)}
                                    hasMore={this.state.hasMore}
                                >
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                {/* <td>
                                                    <div class="vendor-table-check">
                                                        <label class="custon-radio">
                                                            <input type="checkbox" name="starter" />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </td> */}
                                                <td >Name</td>
                                                <td>Email</td>
                                                <td>Created</td>
                                                <td>Active</td>
                                                <td align="center">View Detail</td>
                                                {/* <td align="center">Total Subscription</td>
                                                <td align="center">Subscription Detail</td> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {usersList}

                                            {/* <tr>
                                                    <td>
                                                        <div class="vendor-table-check">
                                                            <label class="custon-radio">
                                                                <input type="checkbox" name="starter" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td align="center">5420</td>
                                                    <td>Loren ipsum</td>
                                                    <td>Nov 04, 2019</td>
                                                    <td>new york</td>
                                                    <td>$499.00</td>
                                                    <td><p class="orange">Due</p></td>
                                                    <td align="center">
                                                        <a class="orange" href="javascript:;">View Details</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="vendor-table-check">
                                                            <label class="custon-radio">
                                                                <input type="checkbox" name="starter" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td align="center">5420</td>
                                                    <td>Loren ipsum</td>
                                                    <td>Nov 04, 2019</td>
                                                    <td>new york</td>
                                                    <td>$499.00</td>
                                                    <td><p class="black">paid</p></td>
                                                    <td align="center">
                                                        <a class="orange" href="javascript:;">View Details</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="vendor-table-check">
                                                            <label class="custon-radio">
                                                                <input type="checkbox" name="starter" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td align="center">5420</td>
                                                    <td>Loren ipsum</td>
                                                    <td>Nov 04, 2019</td>
                                                    <td>new york</td>
                                                    <td>$499.00</td>
                                                    <td><p class="black">paid</p></td>
                                                    <td align="center">
                                                        <a class="orange" href="javascript:;">View Details</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="vendor-table-check">
                                                            <label class="custon-radio">
                                                                <input type="checkbox" name="starter" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td align="center">5420</td>
                                                    <td>Loren ipsum</td>
                                                    <td>Nov 04, 2019</td>
                                                    <td>new york</td>
                                                    <td>$499.00</td>
                                                    <td><p class="black">paid</p></td>
                                                    <td align="center">
                                                        <a class="orange" href="javascript:;">View Details</a>
                                                    </td>
                                                </tr> */}
                                        </tbody>

                                    </table>
                                </InfiniteScroll>
                                {this.state.showLoader ? (
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (null)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            // <div class="main-content">
            //     <div class="container-fluid">

            //         <Header />

            //         <div class="admin-card">
            //             <div class="admin-card-head">
            //                 <h2>Users List</h2>
            //             </div>
            //             <div class="admin-card-box">
            //                 <div class="vendor-table">

            //                     <div class="vendor-table-head">
            //                         <div class="row">
            //                             <div class="col-lg-4 col-md-4">
            //                                 <div class="entries-drop">
            //                                     <label>show</label>
            //                                     <select class="entries-controls">
            //                                         <option value="10">10</option>
            //                                         <option value="20">20</option>
            //                                         <option value="50">50</option>
            //                                         <option value="100">100</option>
            //                                     </select>
            //                                     <p>Entries</p>
            //                                 </div>
            //                             </div>
            //                             <div class="col-lg-8 col-md-8">
            //                                 <div class="vendor-table-head-right">
            //                                     <div class="vendor-table-search mr-2">
            //                                         <input class="form-control" type="text" placeholder="search" />
            //                                     </div>
            //                                     <div class="download-statement-btn">
            //                                         <button type="button" class="hyper-link-btn">download statement</button>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>

            //                     <div class="vendor-table-body">
            //                         <div class="table-responsive">
            //                             <InfiniteScroll
            //                                 dataLength={this.state.users.length}
            //                                 next={() => this.fetchMoreData(false)}
            //                                 hasMore={this.state.hasMore}
            //                             >
            //                                 <table class="table">
            //                                     <thead>
            //                                         <tr>
            //                                             <td>
            //                                                 <div class="vendor-table-check">
            //                                                     <label class="custon-radio">
            //                                                         <input type="checkbox" name="starter" />
            //                                                         <span class="checkmark"></span>
            //                                                     </label>
            //                                                 </div>
            //                                             </td>
            //                                             <td align="center">User ID.</td>
            //                                             <td>Firstname</td>
            //                                             <td>Lastname</td>
            //                                             <td>Email</td>
            //                                             <td>Created</td>
            //                                             <td>Active</td>
            //                                             <td align="center">View Detail</td>
            //                                         </tr>
            //                                     </thead>
            //                                     <tbody>
            //                                         {usersList}
            //                                         {/* <tr>
            //                                         <td>
            //                                             <div class="vendor-table-check">
            //                                                 <label class="custon-radio">
            //                                                     <input type="checkbox" name="starter" />
            //                                                     <span class="checkmark"></span>
            //                                                 </label>
            //                                             </div>
            //                                         </td>
            //                                         <td align="center">5420</td>
            //                                         <td>Loren ipsum</td>
            //                                         <td>Nov 04, 2019</td>
            //                                         <td>new york</td>
            //                                         <td>$499.00</td>
            //                                         <td><p class="orange">Due</p></td>
            //                                         <td align="center">
            //                                             <a class="orange" href="javascript:;">View Details</a>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td>
            //                                             <div class="vendor-table-check">
            //                                                 <label class="custon-radio">
            //                                                     <input type="checkbox" name="starter" />
            //                                                     <span class="checkmark"></span>
            //                                                 </label>
            //                                             </div>
            //                                         </td>
            //                                         <td align="center">5420</td>
            //                                         <td>Loren ipsum</td>
            //                                         <td>Nov 04, 2019</td>
            //                                         <td>new york</td>
            //                                         <td>$499.00</td>
            //                                         <td><p class="black">paid</p></td>
            //                                         <td align="center">
            //                                             <a class="orange" href="javascript:;">View Details</a>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td>
            //                                             <div class="vendor-table-check">
            //                                                 <label class="custon-radio">
            //                                                     <input type="checkbox" name="starter" />
            //                                                     <span class="checkmark"></span>
            //                                                 </label>
            //                                             </div>
            //                                         </td>
            //                                         <td align="center">5420</td>
            //                                         <td>Loren ipsum</td>
            //                                         <td>Nov 04, 2019</td>
            //                                         <td>new york</td>
            //                                         <td>$499.00</td>
            //                                         <td><p class="black">paid</p></td>
            //                                         <td align="center">
            //                                             <a class="orange" href="javascript:;">View Details</a>
            //                                         </td>
            //                                     </tr>
            //                                     <tr>
            //                                         <td>
            //                                             <div class="vendor-table-check">
            //                                                 <label class="custon-radio">
            //                                                     <input type="checkbox" name="starter" />
            //                                                     <span class="checkmark"></span>
            //                                                 </label>
            //                                             </div>
            //                                         </td>
            //                                         <td align="center">5420</td>
            //                                         <td>Loren ipsum</td>
            //                                         <td>Nov 04, 2019</td>
            //                                         <td>new york</td>
            //                                         <td>$499.00</td>
            //                                         <td><p class="black">paid</p></td>
            //                                         <td align="center">
            //                                             <a class="orange" href="javascript:;">View Details</a>
            //                                         </td>
            //                                     </tr> */}
            //                                     </tbody>

            //                                 </table>
            //                             </InfiniteScroll>
            //                         </div>
            //                     </div>

            //                 </div>
            //             </div>
            //         </div>

            //         <div class="main-footer">
            //             <p>© 2020 Subsciety. All Rights Reserved.</p>
            //         </div>

            //     </div>
            // </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getUsersList, searchUser }, dispatch);
};

export default connect(null, mapDispatchToProps)(User_List)